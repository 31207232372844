import React, { useEffect, useRef, useState } from "react";
import logo from "../../../assets/logokit/logo.png";
import login from "../../../assets/login/login.png";
import "./login.scss";
import TextField from "../../common/TextField";
import Button from "../../common/Button";
import { MdVisibility, MdVisibilityOff, MdMode } from 'react-icons/md';
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
import routes from "../../../config/routes";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../../redux/slice/AuthSlice";
import axios from "axios";
import api from "../../../config/api";
import { DotLoader } from "react-spinners";
import { localitiesDetail } from "../../../api/api";
import { panelDashboardLocalityPrediction } from "../../../redux/slice/PanelSlice";

const Login = () => {

  const navigate = useNavigate();

  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [loader, setLoader] = useState(false);

  const [localityToRedirect, setLocalityToRedirect] = useState([]);

  // const authLoader = useSelector((state) => state?.userAuthSlice?.authLoader);
  const redirectSuccess = useSelector((state) => state?.userAuthSlice?.redirectSuccess);
  const userToParse = useSelector((state) => state?.userAuthSlice?.user) || localStorage.getItem('User');
  // const token = useSelector((state) => state?.userAuthSlice?.token);

  const token = useSelector((state) => state?.userAuthSlice?.token) || localStorage.getItem('Token');
  const authLoader = useSelector((state) => state?.userAuthSlice?.authLoader);

  const user = JSON.parse(userToParse) ? JSON.parse(userToParse) : userToParse;
  const userId = user?.user_id;

  const config = {
    headers: { token: `${JSON.parse(token)}` },
  };
  const getAllLocalities = async () => {
    setRedirect(false);
    setLoader(true);
    await localitiesDetail(userId, '', config)
      .then((response) => {
        const cleanedInput = response.data.trim();
        const replacedInput = cleanedInput.replace(/NaN/g, "null");
        const localitesData = JSON.parse(replacedInput);
        setLocalityToRedirect(localitesData);
        localStorage.setItem('locality', localitesData[0]?.localityNo)
        setRedirect(true);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        setRedirect(false);
      })
  }

  useEffect(() => {
    if (user && token) {
      getAllLocalities();
    }
  }, [user, token])

  const dispatch = useDispatch();

  const handleSignIn = (e) => {
    e.preventDefault();

    if (!email) {
      setError('Please type your email.');
    }
    else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
    ) {
      setError('Email format not correct.');
    }
    else if (email && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email) && showPassword === false
    ) {
      setError('');
      setShowPassword(true)
      setPassword('');
    }
    else if (!password) {
      setError('Please type your password.');
    }
    // else if (password.length < 8 || !/[A-Z]/.test(password) || !/[0-9]/.test(password)) {
    //   setError("Password must be at least 8 characters with 1 upper case letter and 1 number.");
    // }
    else {
      const payload = new FormData();

      payload.append("email", email?.toLowerCase());
      payload.append("password", password);

      // const payload = {
      //   email: email,
      //   password: password
      // }
      setError('');
      dispatch(userLogin(payload))
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Tab' || e.key === 'Enter') {
      handleSignIn(e)
    }
  };

  // if (user && token) {
  //   if (user?.level === 'admin' && redirect === true) {
  //     return <Navigate to={`/admin/locality/${localityToRedirect[0]?.localityNo}`} />
  //   }
  //   if (user?.level === 'superior' && redirect === true) {
  //     return <Navigate to={`/superior/locality/${localityToRedirect[0]?.localityNo}`} />
  //   }
  //   if (user?.level === 'user') {
  //     return <Navigate to={routes.panel.user.home} />
  //   }
  // }

  if (user && token) {
    if (user?.level === 'admin' && user?.infoFilled === 'True') {
      return <Navigate to={routes.panel.admin.facts} />
    }
    if (user?.level === 'superior' && user?.infoFilled === 'True') {
      return <Navigate to={routes.panel.superior.facts} />
    }
    if (user?.level === 'user' && user?.infoFilled === 'True') {
      return <Navigate to={routes.panel.user.home} />
    }
  }

  if (user && token) {
    if (user?.level === 'admin' && user?.infoFilled !== 'True') {
      return <Navigate to={routes.panel.admin.isInfoFilled} />
    }
    if (user?.level === 'superior' && user?.infoFilled !== 'True') {
      return <Navigate to={routes.panel.superior.isInfoFilled} />
    }
    if (user?.level === 'user' && user?.infoFilled !== 'True') {
      return <Navigate to={routes.panel.user.isInfoFilled} />
    }
  }

  return (
    <>
      <section id="login-wrapper">
        {
          loader ?
            <div className="loaderWrapper">
              <DotLoader className="login-clip-loader" color={"#3C35B3"} />
              <h1
                style={{
                  color: "#3C35B3",
                  fontSize: "2.4rem",
                }}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;Please wait...
              </h1>
            </div>
            :
            <div className="row">
              <div className="col-md-6 image">
                <img src={logo} alt="logo" className="logo" />
                <img src={login} alt="login" className="login" />
              </div>
              <div className="col-md-6 form-wrapper">
                <h1>Welcome</h1>
                <TextField onKeyDown={handleKeyPress} placeholder="E-mail address" value={email} onChange={(e) => setEmail(e.target.value)} isDisabled={showPassword} icon={showPassword ? <MdMode style={{ cursor: 'pointer' }} onClick={() => {
                  setShowPassword(false)
                }} /> : <></>} />
                {
                  showPassword &&
                  <TextField onKeyDown={handleKeyPress} placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} type={passwordVisibility ? "text" : "password"} icon={passwordVisibility ? <MdVisibility /> : <MdVisibilityOff />} onClick={() => setPasswordVisibility(!passwordVisibility)} />
                }
                {
                  error && <p className="error-message">{error}</p>
                }
                {
                  authLoader ?
                    <div className="loaderWrapper">
                      <DotLoader className="login-clip-loader" color={"#3C35B3"} />
                      <h1
                        style={{
                          color: "#3C35B3",
                          fontSize: "2.4rem",
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;Logging you in...
                      </h1>
                    </div>
                    :
                    <Button id="primary-button" className="sign-in" onClick={handleSignIn}>Sign in </Button>
                }

              </div>
              <img src={login} alt="login" className="login-bottom" />
            </div>
        }
      </section>
    </>
  );
};

export default Login;
