import routes from "../config/routes";

const SupervisorSettingMenu = [
  {
    id: "1",
    path: routes?.panel?.superior?.settings?.generalInfo,
    name: "General info",
  },
  {
    id: "2",
    path: routes?.panel?.superior?.settings?.password,
    name: "Password",
  },
  {
    id: "3",
    path: routes?.panel?.superior?.settings?.language,
    name: "Language",
  },
];

export default SupervisorSettingMenu;
