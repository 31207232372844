import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetailsInfo } from "../../../redux/slice/AuthSlice";

const useGeneralInfoForm = (validate) => {

  const userToParse = useSelector((state) => state?.userAuthSlice?.user) || localStorage.getItem('User');

  const user = JSON.parse(userToParse) ? JSON.parse(userToParse) : userToParse;
  const token = useSelector((state) => state?.userAuthSlice?.token);

  const dispatch = useDispatch();

  useEffect(() => {
    const userId = user?.user_id;
    const config = {
      headers: { token: `${JSON.parse(token)}` },
    }
    dispatch(getUserDetailsInfo({ userId, config }))
  }, []);

  const {firstname, lastname, email, company, phoneNumber} = user;

  const [values, setValues] = useState({
    firstname: firstname !== 'nan' ? firstname : '',
    lastname: lastname !== 'nan' ? lastname : '',
    email: email !=='nan' ? email : '',
    company: company !== 'nan' ? company : '',
    phoneNumber: phoneNumber !== 'nan' ? phoneNumber : '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const getErrors = (e) => {
    e.preventDefault();
    setErrors(Object.assign({}, validate(values)));
  };

  return { handleChange, values, getErrors, errors };
};

export default useGeneralInfoForm;
