import React, { useEffect, useState } from "react";
import "./sidebar.scss";
import { selectLocaltiy, settingMenu } from "../../constants/Home";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "../../components/common/Button";
import routes from "../../config/routes";

import { useDispatch, useSelector } from "react-redux";
import {
  panelDashboardLocalityPrediction,
  panelHomeLocalities,
} from "../../redux/slice/PanelSlice";
import { ClimbingBoxLoader, DotLoader } from "react-spinners";
import SupervisorSettingMenu from "../../constants/SupervisorSettings";
import AdminSettingMenu from "../../constants/AdminSettings";
import { getUserDetailsInfo } from "../../redux/slice/AuthSlice";
import TextField from "../../components/common/TextField";
import { MdSearch } from "react-icons/md";

export const Sidebar = () => {
  const userToParse = useSelector((state) => state?.userAuthSlice?.user);

  const user = JSON.parse(userToParse) ? JSON.parse(userToParse) : userToParse;

  const token = useSelector((state) => state?.userAuthSlice?.token);

  const userId = user?.user_id;

  const dispatch = useDispatch();

  const [localitiesOverViewSearch, setLocalitiesOverviewSearch] = useState("");

  const config = {
    headers: { Token: `${JSON.parse(token)}` },
  };

  console.log({token})

  useEffect(() => {
    dispatch(panelDashboardLocalityPrediction({ userId, search: '', config }));
  }, []);

  const handleKeyPressOverview = (e) => {
    if (e.key === "Tab" || e.key === "Enter") {
      handleLocalitiesOverviewSearch(e);
    }
  };

  const handleLocalitiesOverviewSearch = (e) => {
    e.preventDefault();
    dispatch(
      panelDashboardLocalityPrediction({
        userId,
        search: localitiesOverViewSearch,
        config,
      })
    );
  };

  const panelLoader = useSelector((state) => state?.PanelSlice?.panelLoader);
  const loycalityPredictionList = useSelector(
    (state) => state?.PanelSlice?.dashboardLocalityPrediction
  );

  return (
    <>
      <section id="sidebar-wrapper">
        <h2>Localities</h2>
        <TextField
          placeholder="search"
          icon={<MdSearch size={20} style={{ cursor: "pointer" }} onClick={handleLocalitiesOverviewSearch}/>}
          value={localitiesOverViewSearch}
          onChange={(e) => setLocalitiesOverviewSearch(e.target.value)}
          onKeyDown={handleKeyPressOverview}
        />
        {panelLoader ? (
          <div
            className="col-lg-12 col-md-12 col-sm-12 col-12"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // overflowY: "hidden",
            }}
          >
            <DotLoader color={"#3C35B3"} size={40} />
          </div>
        ) : (
          <ul>
            {loycalityPredictionList?.map((item, index) => {
              return (
                <>
                  {
                    <NavLink
                      to={
                        user?.level === "admin"
                          ? `/admin/locality/${item?.localityNo}`
                          : user?.level === "superior"
                          ? `/superior/locality/${item?.localityNo}`
                          : `/user/locality/${item?.localityNo}`
                      }
                      // isActive={`${
                      //   window?.location?.pathname?.includes(item?.localityNo)
                      //     ? "active"
                      //     : ""
                      // }`}
                      className={({ isActive, isPending }) =>
                        isPending
                          ? "pending"
                          : isActive &&
                            window?.location?.pathname?.includes(
                              item?.localityNo
                            )
                          ? "active"
                          : ""
                      }
                    >
                      {item?.localityName}
                    </NavLink>
                  }
                </>
              );
            })}
          </ul>
        )}
      </section>
    </>
  );
};

export const SettingSidebar = () => {
  const navigate = useNavigate();
  const userToParse = useSelector((state) => state?.userAuthSlice?.user);

  const token = useSelector((state) => state?.userAuthSlice?.token);

  const dispatch = useDispatch();

  const user = JSON.parse(userToParse) ? JSON.parse(userToParse) : userToParse;

  useEffect(() => {
    const userId = user?.user_id;
    const config = {
      headers: { token: `${JSON.parse(token)}` },
    };
    const interval = setInterval(() => {
      dispatch(getUserDetailsInfo({ userId, config }));
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <section id="sidebar-wrapper">
        <h2 className="account-setting">Account Setting</h2>
        <p>Change your profile and account settings</p>
        <ul>
          {user?.level === "admin" && (
            <>
              {AdminSettingMenu?.map((item) => {
                return (
                  <>
                    <NavLink to={item?.path}>{item?.name}</NavLink>
                  </>
                );
              })}
            </>
          )}
          {user?.level === "superior" && (
            <>
              {SupervisorSettingMenu?.map((item) => {
                return (
                  <>
                    <NavLink to={item?.path}>{item?.name}</NavLink>
                  </>
                );
              })}
            </>
          )}
          {user?.level === "user" && (
            <>
              {settingMenu?.map((item) => {
                return (
                  <>
                    <NavLink to={item?.path}>{item?.name}</NavLink>
                  </>
                );
              })}
            </>
          )}
        </ul>
        <Button
          id="primary-button"
          className="logout-button"
          onClick={() => navigate(routes?.auth?.login)}
          featureIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M1.66667 20C1.22222 20 0.833333 19.8333 0.5 19.5C0.166667 19.1667 0 18.7778 0 18.3333V1.66667C0 1.22222 0.166667 0.833333 0.5 0.5C0.833333 0.166667 1.22222 0 1.66667 0H9.97222V1.66667H1.66667V18.3333H9.97222V20H1.66667ZM15.1667 14.8611L13.9722 13.6667L16.8056 10.8333H6.66667V9.16667H16.75L13.9167 6.33333L15.1111 5.13889L20 10.0278L15.1667 14.8611Z"
                fill="white"
              />
            </svg>
          }
        >
          Log Out
        </Button>
      </section>
    </>
  );
};
