import React, { useEffect } from "react";
import "./generalInfo.scss";
import TextField from "../../common/TextField";
import Button from "../../common/Button";
import useGeneralInfoForm from "./useGeneralInfoForm";
import validate from "./validation";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetailsInfo } from "../../../redux/slice/AuthSlice";
import { updateUserPersonalInfo } from "../../../redux/slice/SettingSlice";
import { DotLoader } from "react-spinners";
import { Navigate } from "react-router-dom";
import routes from "../../../config/routes";

const GeneralInfo = () => {

  const userToParse = useSelector((state) => state?.userAuthSlice?.user);
  const token = useSelector((state) => state?.userAuthSlice?.token);

  const user = userToParse !== '' ? JSON.parse(userToParse) : userToParse;
  const userId = user?.user_id;

  const settingLoader = useSelector((state) => state?.SettingSlice?.settingLoader);

  const dispatch = useDispatch();

  useEffect(() => {
    const userId = user?.user_id;
    const config = {
      headers: { token: `${JSON.parse(token)}` },
    }
    dispatch(getUserDetailsInfo({ userId, config }))

  }, [])

  const { values, getErrors, handleChange, errors } = useGeneralInfoForm(validate);

  const handleSaveChanges = (e) => {
    e.preventDefault();
    getErrors(e);
    const validateErrors = validate(values);
    let isValid = true;
    Object.keys(validateErrors).forEach((key) => {
      if (validateErrors[key]) {
        isValid = false;
      }
    });

    if (isValid) {
      const config = {
        headers: { token: `${JSON.parse(token)}` },
      }

      const payload = new FormData();

      payload.append('firstname', values.firstname)
      payload.append("email", values.email);
      payload.append("lastname", values.lastname);
      payload.append("phoneNumber", values.phoneNumber);
      payload.append("company", values.company);

      // const payload = {
      //   firstname: values.firstname,
      //   lastname: values.lastname,
      //   company: values.company,
      //   email: values.email,
      //   phoneNumber: values.phoneNumber,
      // }

      dispatch(updateUserPersonalInfo({ userId, payload, config }))
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Tab' || e.key === 'Enter') {
      handleSaveChanges(e)
    }
  };

  if (user && token) {
    if (user?.level === 'admin' && user?.infoFilled !== 'True') {
      return <Navigate to={routes.panel.admin.isInfoFilled} />
    }
    if (user?.level === 'superior' && user?.infoFilled !== 'True') {
      return <Navigate to={routes.panel.superior.isInfoFilled} />
    }
    if (user?.level === 'user' && user?.infoFilled !== 'True') {
      return <Navigate to={routes.panel.user.isInfoFilled} />
    }
  }

  return (
    <>
      <section id="general-info-wrapper">
        <h2>General Info</h2>
        <div className="row">
          <div className="col-8">
            <div className="row">
              <div className="col-6 pl-0">
                <TextField placeholder="First Name" name='firstname' value={values.firstname} onChange={handleChange} onKeyDown={handleKeyPress} />
                {
                  errors?.firstname && <p className="error-message">{errors?.firstname}</p>
                }
              </div>
              <div className="col-6 pr-0">
                <TextField placeholder="Sure Name" name='lastname' value={values.lastname} onChange={handleChange} onKeyDown={handleKeyPress} />
                {
                  errors?.lastname && <p className="error-message">{errors?.lastname}</p>
                }
              </div>
            </div>
            <div className="col-12">
              <TextField type="text" placeholder="Number" name='phoneNumber' value={values.phoneNumber} onChange={handleChange} onKeyDown={handleKeyPress} />
              {
                errors?.phoneNumber && <p className="error-message">{errors?.phoneNumber}</p>
              }
            </div>
            <div className="col-12">
              <TextField placeholder="Email" name='email' value={values.email} onChange={handleChange} onKeyDown={handleKeyPress} isDisabled />
              {
                errors?.email && <p className="error-message">{errors?.email}</p>
              }
            </div>
            <div className="col-12">
              <TextField placeholder="Company" name='company' value={values.company} onChange={handleChange} onKeyDown={handleKeyPress} isDisabled />
              {
                errors?.company && <p className="error-message">{errors?.company}</p>
              }
            </div>
            {
              settingLoader ?
                <div className="loaderWrapper">
                  <DotLoader className="login-clip-loader" color={"#3C35B3"} />
                  <h1
                    style={{
                      color: "#3C35B3",
                      fontSize: "2.4rem",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;Please wait...
                  </h1>
                </div>
                :
                <Button id="primary-button" className="save-changing" onClick={handleSaveChanges}>Save Changes</Button>
            }
          </div>
        </div>
      </section>
    </>
  );
};

export default GeneralInfo;