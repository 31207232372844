import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Router,
  useParams,
} from "react-router-dom";
import routes from "./config/routes";
import Login from "./components/Authentication/Login/Login";
import Layout from "./layout/Layout";
import Home from "./components/Home/Home";
import DashboardLayout from "./layout/DashboardLayout";
import Krakasen from "./components/Dashboard/Krakasen/Krakasen";
import Facts from "./components/Facts/Facts";
import User from "./components/Facts/User/User";
import GeneralInfo from "./components/Setting/GeneralInfo/GeneralInfo";
import Password from "./components/Setting/Password/Password";
import Language from "./components/Setting/Language/Language";
import AdminRoute from "./privateRoutes/AdminRoute";
import UserRoute from "./privateRoutes/UserRoute";
import Dashboard from "./components/Dashboard/Krakasen/Krakasen";
import SupervisorRoute from "./privateRoutes/SupervisorRoute";
import { useDispatch, useSelector } from "react-redux";
import PersonalInfoFilled from "./components/PersonalInfoFilled/PersonalInfoFilled";

const App = () => {

  const userToParse = useSelector((state) => state?.userAuthSlice?.user);

  console.log({ userToParse })

  const user = (userToParse !== '' && userToParse !== null) ? JSON.parse(userToParse) : userToParse;

  console.log({ user })

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if(user !== null && user !== '') {
  //     dispatch()
  //   }  
  // }, [user])
  // const { id } = useParams();
  const localitiesNo = window?.location?.pathname?.includes("locality")
    ? window?.location?.pathname?.split("/")[3]
    : null;

  console.log({ localitiesNo })

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path={routes.auth.login} element={<Login />} />

          {/* Admin */}
          <Route
            path={routes.panel.admin.localities}
            element={
              <AdminRoute>
                <DashboardLayout>
                  <Dashboard />
                </DashboardLayout>
              </AdminRoute>
            }
          />
          <Route
            path={routes.panel.admin.facts}
            element={
              <AdminRoute>
                <Layout>
                  <Facts />
                </Layout>
              </AdminRoute>
            }
          />
          <Route
            path={routes.panel.admin.isInfoFilled}
            element={
              <AdminRoute>
                {/* <Layout> */}
                  <PersonalInfoFilled />
                {/* </Layout> */}
              </AdminRoute>
            }
          />
          <Route
            path={routes.panel.admin.userDataFacts}
            element={
              <AdminRoute>
                <Layout>
                  <User />
                </Layout>
              </AdminRoute>
            }
          />
          <Route
            path={routes.panel.admin.userDataFacts}
            element={
              <AdminRoute>
                <Layout>
                  <User />
                </Layout>
              </AdminRoute>
            }
          />
          <Route
            path={routes.panel.admin.settings.generalInfo}
            element={
              <AdminRoute>
                <DashboardLayout>
                  <GeneralInfo />
                </DashboardLayout>
              </AdminRoute>
            }
          />
          <Route
            path={routes.panel.admin.settings.language}
            element={
              <AdminRoute>
                <DashboardLayout>
                  <Language />
                </DashboardLayout>
              </AdminRoute>
            }
          />
          <Route
            path={routes.panel.admin.settings.password}
            element={
              <AdminRoute>
                <DashboardLayout>
                  <Password />
                </DashboardLayout>
              </AdminRoute>
            }
          />

          {/* User Route */}
          <Route
            path={routes.panel.user.home}
            element={
              <UserRoute>
                <Layout>
                  <Home />
                </Layout>
              </UserRoute>
            }
          />
          <Route
            path={routes.panel.user.localities}
            element={
              <UserRoute>
                <DashboardLayout>
                  <Krakasen />
                </DashboardLayout>
              </UserRoute>
            }
          />
          <Route
            path={routes.panel.user.isInfoFilled}
            element={
              <UserRoute>
                {/* <Layout> */}
                  <PersonalInfoFilled />
                {/* </Layout> */}
              </UserRoute>
            }
          />
          <Route
            path={routes.panel.user.setting.generalInfo}
            element={
              <UserRoute>
                <DashboardLayout>
                  <GeneralInfo />
                </DashboardLayout>
              </UserRoute>
            }
          />
          <Route
            path={routes.panel.user.setting.language}
            element={
              <UserRoute>
                <DashboardLayout>
                  <Language />
                </DashboardLayout>
              </UserRoute>
            }
          />
          <Route
            path={routes.panel.user.setting.password}
            element={
              <UserRoute>
                <DashboardLayout>
                  <Password />
                </DashboardLayout>
              </UserRoute>
            }
          />

          {/* Sueprvisor Route */}
          <Route
            path={routes.panel.superior.localities}
            element={
              <SupervisorRoute>
                <DashboardLayout>
                  <Dashboard />
                </DashboardLayout>
              </SupervisorRoute>
            }
          />
          <Route
            path={routes.panel.superior.isInfoFilled}
            element={
              <SupervisorRoute>
                {/* <Layout> */}
                  <PersonalInfoFilled />
                {/* </Layout> */}
              </SupervisorRoute>
            }
          />
          <Route
            path={routes.panel.superior.facts}
            element={
              <SupervisorRoute>
                <Layout>
                  <Facts />
                </Layout>
              </SupervisorRoute>
            }
          />
          {/* <Route
            path={routes.panel.superior.userDataFacts}
            element={
              <SupervisorRoute>
                <Layout>
                  <User />
                </Layout>
              </SupervisorRoute>
            }
          /> */}
          <Route
            path={routes.panel.superior.userDataFacts}
            element={
              <SupervisorRoute>
                <Layout>
                  <User />
                </Layout>
              </SupervisorRoute>
            }
          />
          <Route
            path={routes.panel.superior.settings.generalInfo}
            element={
              <SupervisorRoute>
                <DashboardLayout>
                  <GeneralInfo />
                </DashboardLayout>
              </SupervisorRoute>
            }
          />
          <Route
            path={routes.panel.superior.settings.language}
            element={
              <SupervisorRoute>
                <DashboardLayout>
                  <Language />
                </DashboardLayout>
              </SupervisorRoute>
            }
          />
          <Route
            path={routes.panel.superior.settings.password}
            element={
              <SupervisorRoute>
                <DashboardLayout>
                  <Password />
                </DashboardLayout>
              </SupervisorRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
