import React, { useState } from "react";
import "./password.scss";
import TextField from "../../common/TextField";
import Button from "../../common/Button";
import useNewPassword from "./useNewPassword";
import validate from "./validation";
import { useDispatch, useSelector } from "react-redux";
import { updateUserPassword } from "../../../redux/slice/SettingSlice";
import { DotLoader } from "react-spinners";
import { Navigate } from "react-router-dom";
import routes from "../../../config/routes";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

const Password = () => {
  const dispatch = useDispatch();
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [repeatPasswordVisibility, setRepeatPasswordVisibility] = useState(false);
  const [currentPasswordVisibility, setCurrentPasswordVisibility] = useState(false);
  const userToParse = useSelector((state) => state?.userAuthSlice?.user);
  const token = useSelector((state) => state?.userAuthSlice?.token);

  const user = userToParse !== "" ? JSON.parse(userToParse) : userToParse;
  const userId = user?.user_id;

  const settingLoader = useSelector(
    (state) => state?.SettingSlice?.settingLoader
  );

  const { values, getErrors, handleChange, errors } = useNewPassword(validate);

  const config = {
    headers: { token: `${JSON.parse(token)}` },
  };

  const handleSavePasswordChanges = (e) => {
    e.preventDefault();
    getErrors(e);
    const validateErrors = validate(values);
    let isValid = true;
    Object.keys(validateErrors).forEach((key) => {
      if (validateErrors[key]) {
        isValid = false;
      }
    });

    if (isValid) {
      const payload = new FormData();

      payload.append("oldPassword", values.currentPassword);
      payload.append("confirmNewPassword", values.newPassword);

      dispatch(updateUserPassword({ userId, payload, config }));
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Tab" || e.key === "Enter") {
      handleSavePasswordChanges(e);
    }
  };

  if (user && token) {
    if (user?.level === "admin" && user?.infoFilled !== "True") {
      return <Navigate to={routes.panel.admin.isInfoFilled} />;
    }
    if (user?.level === "superior" && user?.infoFilled !== "True") {
      return <Navigate to={routes.panel.superior.isInfoFilled} />;
    }
    if (user?.level === "user" && user?.infoFilled !== "True") {
      return <Navigate to={routes.panel.user.isInfoFilled} />;
    }
  }

  return (
    <section id="password-wrapper">
      <h2>Change Password</h2>
      <div className="row">
        <div className="col-8">
          <div className="col-12">
            <TextField
              placeholder="Current Passsword"
              name="currentPassword"
              value={values.currentPassword}
              onChange={handleChange}
              onKeyDown={handleKeyPress}
              type={currentPasswordVisibility ? "text" : "password"}
              icon={currentPasswordVisibility ? <MdVisibility /> : <MdVisibilityOff />}
              onClick={() => setCurrentPasswordVisibility(!currentPasswordVisibility)}
            />
          </div>
          {errors?.currentPassword && (
            <p className="error-message">{errors?.currentPassword}</p>
          )}
          <div className="col-12">
            <TextField
              placeholder="New Password"
              name="newPassword"
              value={values.newPassword}
              onChange={handleChange}
              onKeyDown={handleKeyPress}
              type={passwordVisibility ? "text" : "password"}
              icon={passwordVisibility ? <MdVisibility /> : <MdVisibilityOff />}
              onClick={() => setPasswordVisibility(!passwordVisibility)}
            />
          </div>
          {errors?.newPassword && (
            <p className="error-message">{errors?.newPassword}</p>
          )}
          <div className="col-12">
            <TextField
              placeholder="Repeat Password"
              name="confirmNewPassword"
              value={values.confirmNewPassword}
              onChange={handleChange}
              onKeyDown={handleKeyPress}
              type={repeatPasswordVisibility ? "text" : "password"}
              icon={repeatPasswordVisibility ? <MdVisibility /> : <MdVisibilityOff />}
              onClick={() => setRepeatPasswordVisibility(!repeatPasswordVisibility)}
            />
          </div>
          {errors?.confirmNewPassword && (
            <p className="error-message">{errors?.confirmNewPassword}</p>
          )}
          {settingLoader ? (
            <div className="loaderWrapper">
              <DotLoader className="login-clip-loader" color={"#3C35B3"} />
              <h1
                style={{
                  color: "#3C35B3",
                  fontSize: "2.4rem",
                }}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;Please wait...
              </h1>
            </div>
          ) : (
            <Button
              id="primary-button"
              className="save-changing"
              onClick={handleSavePasswordChanges}
            >
              Save Changes
            </Button>
          )}
        </div>
      </div>
    </section>
  );
};

export default Password;
