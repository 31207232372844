import React, { useEffect, useState } from "react";
import "./krakasen.scss";
import {
  environmentalFacts,
  krakasenLocalities,
} from "../../../constants/Krakasen";
import CalculatorAndEnvFacts from "./CalculatorAndEnvFacts/CalculatorAndEnvFacts";
import Chart from "./Chart/Chart";
import { useDispatch, useSelector } from "react-redux";
import {
  panelDashboardLocalityPrediction,
  panelHomeLocalities,
} from "../../../redux/slice/PanelSlice";
import { ClimbingBoxLoader, DotLoader } from "react-spinners";
import { Navigate, useLocation } from "react-router-dom";
import axios from "axios";
import { selectLocaltiy } from "../../../constants/Home";
import routes from "../../../config/routes";

const Dashboard = () => {
  const location = useLocation();

  const [loader, setLoader] = useState(false);
  const [localities, setLocalites] = useState([]);

  const userToParse = useSelector((state) => state?.userAuthSlice?.user);

  const token = useSelector((state) => state?.userAuthSlice?.token);

  const user = JSON.parse(userToParse) ? JSON.parse(userToParse) : userToParse;

  const userId = user?.user_id;

  const config = {
    headers: { token: `${JSON.parse(token)}` },
  };

  const dispatch = useDispatch();

  const Host = "https://anlice.no/app";

  const fetchLocalities = async () => {
    setLoader(true);
    await axios
      .get(`${Host}/userLocalities/${userId}`, config)
      .then((response) => {
        const cleanedInput = response.data.trim();
        const replacedInput = cleanedInput.replace(/NaN/g, "null");
        const localitesData = JSON.parse(replacedInput);
        setLocalites(localitesData);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log({ err });
      });
    // await dispatch(panelDashboardLocalityPrediction({ userId, search: '', config }))
  };

  useEffect(() => {
    fetchLocalities();

    // const check = localStorage.getItem('check');
    // console.log({check})

    // if (check === null) {
    //   localStorage.setItem('check', true);
    //   window.location.reload();
    // }
    // else {
    // dispatch(panelDashboardLocalityPrediction({ userId, config }))
    // dispatch(panelHomeLocalities(userId))
    // }
  }, []);

  let selectedLocailty = null;

  const panelLoader = useSelector((state) => state?.PanelSlice?.panelLoader);
  const loycalityPredictionList = useSelector(
    (state) => state?.PanelSlice?.dashboardLocalityPrediction
  );

  if (localities?.length !== 0) {
    selectedLocailty =
      localities?.length !== 0
        ? localities?.find(
          (item) =>
            Number(location?.pathname?.split("/")[3]) === item?.localityNo
        )
        : null;
  }

  if (user && token) {
    if (user?.level === 'admin' && user?.infoFilled !== 'True') {
      return <Navigate to={routes.panel.admin.isInfoFilled} />
    }
    if (user?.level === 'superior' && user?.infoFilled !== 'True') {
      return <Navigate to={routes.panel.superior.isInfoFilled} />
    }
    if (user?.level === 'user' && user?.infoFilled !== 'True') {
      return <Navigate to={routes.panel.user.isInfoFilled} />
    }
  }

  return (
    <>
      {loader ? (
        <div
          className="col-lg-12 col-md-12 col-sm-12 col-12"
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // overflowY: 'hidden'
          }}
        >
          <DotLoader color={"#3C35B3"} />
          <h1
            style={{
              color: "#3C35B3",
              fontSize: "2.4rem",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;Please Wait...
          </h1>
        </div>
      ) : (
        <>
          {user?.level !== "user" && (
            <section id="dashbord-facts-wrapper">
              <h2>{`${selectedLocailty?.localityName} ${selectedLocailty?.localityNo} ${selectedLocailty?.municipality}`}</h2>
              <div className="environmental-facts">
                <div className="facts-card-wrapper">
                  <div className="facts-card">
                    <div className="card">{selectedLocailty?.latest_lice_value ? (selectedLocailty?.latest_lice_value)?.toFixed(2) : '0'}</div>
                    <p>Last registered lice</p>
                  </div>
                  <div className="facts-card">
                    <div className="card">{(selectedLocailty?.mae)?.toFixed(2)}</div>
                    <p>MAE avg </p>
                  </div>
                  <div className="facts-card">
                    <div className="card">{(selectedLocailty?.max_diff)}</div>
                    <p>Max diff week</p>
                  </div>
                  <div className="facts-card">
                    <div className="card">{selectedLocailty?.missed_weeks}</div>
                    <p>Weeks missed by +- 0,1 lice</p>
                  </div>
                  <div className="facts-card">
                    <div className="card">{selectedLocailty?.best_model}</div>
                    <p>ML model used</p>
                  </div>
                  <div className="facts-card">
                    <div className="card">{selectedLocailty?.tuning ? selectLocaltiy?.tuning : '--'}</div>
                    <p>Tuning used</p>
                  </div>
                  <div className="facts-card">
                    <div className="card">{selectedLocailty?.data_points ? (selectedLocailty?.data_points) : '0'}</div>
                    <p>Datapoints on locality</p>
                  </div>
                  <div className="facts-card">
                    <div className="card">{selectedLocailty?.rank}</div>
                    <p>Ranking Lice Level</p>
                  </div>
                  <div className="facts-card">
                    <div className="card">{selectedLocailty?.neighbours ? JSON.parse(selectedLocailty?.neighbours)?.length : '0'}</div>
                    <p>No of neighbours used</p>
                  </div>
                  {/* {environmentalFacts?.map((item) => {
                    return (
                      <>
                        <div className="facts-card">
                          <div className="card">--</div>
                          <p>{item?.define}</p>
                        </div>
                      </>
                    );
                  })} */}
                </div>
              </div>
            </section>
          )}
          <section id="krakasen-wrapper">
            {user?.level === "user" && (
              <h2>{`${selectedLocailty?.localityName} ${selectedLocailty?.localityNo} ${selectedLocailty?.municipality}`}</h2>
            )}
            <div className="lice-top-card">
              <div className="card-value">
                <h5>{selectedLocailty?.latest_lice_value?.toFixed(2)}</h5>
                <p>Lice this week</p>
              </div>
              <div className="card-value">
                <h5>{selectedLocailty?.pred_5th_value?.toFixed(2)}</h5>
                <p>Lice in 5 weeks</p>
              </div>
              <div className="card-value">
                {
                  selectedLocailty?.week_for_treatment === -1 ?
                    <h6>
                      No recommended treatment
                    </h6>
                    :
                    <h5>
                      {
                        selectedLocailty?.week_for_treatment
                      }
                    </h5>
                }
                <p>Week for recomended treatment</p>
              </div>
            </div>
          </section>
          <Chart
            selectedData={
              selectedLocailty !== undefined ? selectedLocailty : null
            }
          />
          <CalculatorAndEnvFacts localitySelected={selectedLocailty} />
        </>
      )}
    </>
  );
};

export default Dashboard;
