import React, { useEffect, useState } from "react";
import "./facts.scss";
import Button from "../common/Button";
import { environmentalFacts } from "../../constants/Krakasen";
import { Link, Navigate, useNavigate } from "react-router-dom";
import routes from "../../config/routes";
import { useDispatch, useSelector } from "react-redux";
import { getSummaryDetails } from "../../redux/slice/LocalitySlice";
import { DotLoader } from "react-spinners";
import { panelDashboardLocalityPrediction } from "../../redux/slice/PanelSlice";
import { localitiesDetail } from "../../api/api";

const Facts = () => {
  const navigate = useNavigate();
  const userToParse = useSelector((state) => state?.userAuthSlice?.user);

  const [localityToRedirect, setLocalityToRedirect] = useState([]);
  const [showWord, setShowWord] = useState(false);

  const user = JSON.parse(userToParse) ? JSON.parse(userToParse) : userToParse;
  const userId = user?.user_id;
  const token = useSelector((state) => state?.userAuthSlice?.token);
  const config = {
    headers: { token: `${JSON.parse(token)}` },
  };

  const handleUserNavigate = () => {
    if (user?.level === "admin") {
      navigate(routes?.panel?.admin?.userDataFacts, {
        state: { dataState: "false" },
      });
    }
    if (user?.level === "superior") {
      navigate(routes?.panel?.superior.userDataFacts, {
        state: { dataState: "false" },
      });
    }
  };

  const handleDataNavigate = () => {
    if (user?.level === "admin") {
      navigate(routes?.panel?.admin?.userDataFacts, {
        state: { dataState: "true" },
      });
    }
    if (user?.level === "superior") {
      navigate(routes?.panel?.superior.userDataFacts, {
        state: { dataState: "true" },
      });
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSummaryDetails({ userId, config }));
  }, []);

  const getAllLocalities = async () => {
    setShowWord(false);
    await localitiesDetail(userId, "", config)
      .then((response) => {
        const cleanedInput = response.data.trim();
        const replacedInput = cleanedInput.replace(/NaN/g, "null");
        const localitesData = JSON.parse(replacedInput);
        setLocalityToRedirect(localitesData);
        localStorage.setItem("locality", localitesData[0]?.localityNo);
        setShowWord(true);
      })
      .catch((err) => {
        setShowWord(false);
        console.log({ err });
      });
  };

  useEffect(() => {
    getAllLocalities();
  }, []);

  const localityLoader = useSelector(
    (state) => state?.LocalitySlice?.localityLoader
  );

  const summaryData = useSelector((state) => state?.LocalitySlice?.summaryData);

  if (user && token) {
    if (user?.level === 'admin' && user?.infoFilled !== 'True') {
      return <Navigate to={routes.panel.admin.isInfoFilled} />
    }
    if (user?.level === 'superior' && user?.infoFilled !== 'True') {
      return <Navigate to={routes.panel.superior.isInfoFilled} />
    }
    if (user?.level === 'user' && user?.infoFilled !== 'True') {
      return <Navigate to={routes.panel.user.isInfoFilled} />
    }
  }

  return (
    <>
      <section id="facts-wrapper">
        {showWord && (
          <Link
            to={
              user?.level === "admin"
                ? `/admin/locality/${localityToRedirect[0]?.localityNo}`
                : `/superior/locality/${localityToRedirect[0]?.localityNo}`
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="20"
              viewBox="0 0 12 20"
              fill="none"
            >
              <path
                d="M0.656253 10L10.1563 0.5L11.8583 2.20208L4.02084 10L11.8188 17.7979L10.1167 19.5L0.656253 10Z"
                fill="#3C35B3"
              />
            </svg>
            &nbsp; &nbsp; Dashboard
          </Link>
        )}
        <div className="button-wrapper">
          <Button
            id="secondary-button"
            className="user-button"
            featureIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <path
                  d="M4.615 20.3125C5.98 19.4458 7.32875 18.7904 8.66125 18.3462C9.99375 17.9021 11.44 17.68 13 17.68C14.56 17.68 16.0117 17.9021 17.355 18.3462C18.6983 18.7904 20.0525 19.4458 21.4175 20.3125C22.3708 19.1425 23.0479 17.9617 23.4487 16.77C23.8496 15.5783 24.05 14.3217 24.05 13C24.05 9.85833 22.9937 7.23125 20.8812 5.11875C18.7687 3.00625 16.1417 1.95 13 1.95C9.85833 1.95 7.23125 3.00625 5.11875 5.11875C3.00625 7.23125 1.95 9.85833 1.95 13C1.95 14.3217 2.15583 15.5783 2.5675 16.77C2.97917 17.9617 3.66167 19.1425 4.615 20.3125ZM12.994 13.975C11.7413 13.975 10.6871 13.5451 9.83125 12.6852C8.97542 11.8253 8.5475 10.7691 8.5475 9.51646C8.5475 8.26382 8.97743 7.20958 9.83729 6.35375C10.6972 5.49792 11.7534 5.07 13.006 5.07C14.2587 5.07 15.3129 5.49993 16.1687 6.35979C17.0246 7.21966 17.4525 8.27591 17.4525 9.52854C17.4525 10.7812 17.0226 11.8354 16.1627 12.6912C15.3028 13.5471 14.2466 13.975 12.994 13.975ZM12.9868 26C11.1853 26 9.49236 25.6587 7.9079 24.9762C6.32344 24.2937 4.94517 23.3621 3.77309 22.1812C2.60103 21.0004 1.67917 19.6209 1.0075 18.0427C0.335833 16.4645 0 14.7782 0 12.9837C0 11.1893 0.34125 9.50303 1.02375 7.92483C1.70625 6.34661 2.63792 4.9725 3.81875 3.8025C4.99958 2.6325 6.37911 1.70625 7.95733 1.02375C9.53553 0.34125 11.2218 0 13.0162 0C14.8107 0 16.497 0.34125 18.0752 1.02375C19.6534 1.70625 21.0275 2.6325 22.1975 3.8025C23.3675 4.9725 24.2937 6.34833 24.9762 7.93C25.6587 9.51167 26 11.1988 26 12.9914C26 14.7839 25.6587 16.4685 24.9762 18.0451C24.2937 19.6217 23.3675 21.0004 22.1975 22.1812C21.0275 23.3621 19.6503 24.2937 18.0658 24.9762C16.4813 25.6587 14.7884 26 12.9868 26Z"
                  fill="#3C35B3"
                />
              </svg>
            }
            // onClick={() => navigate(routes?.panel?.admin.userDataFacts)}
            onClick={handleUserNavigate}
          >
            User
          </Button>
          <Button
            id="secondary-button"
            className="user-button"
            featureIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <path
                  d="M0 6.9875H26V0H0V6.9875ZM0 16.4937H26V9.50625H0V16.4937ZM0 26H26V19.0125H0V26ZM2.275 4.7125V2.275H4.44167V4.7125H2.275ZM2.275 14.2187V11.7812H4.44167V14.2187H2.275ZM2.275 23.725V21.2875H4.44167V23.725H2.275Z"
                  fill="#3C35B3"
                />
              </svg>
            }
            onClick={handleDataNavigate}
          >
            Data
          </Button>
        </div>
        {localityLoader ? (
          <div className="loaderWrapper">
            <DotLoader className="login-clip-loader" color={"#3C35B3"} />
            <h1
              style={{
                color: "#3C35B3",
                fontSize: "2.4rem",
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;Fetching details...
            </h1>
          </div>
        ) : (
          <div className="environmental-facts">
            <div className="facts-card-wrapper">
              <div className="facts-card">
                <div className="card">{summaryData?.Last_run}</div>
                <p>Last time code run</p>
              </div>
              <div className="facts-card">
                <div className="card">
                  {Number(summaryData?.meanError)?.toFixed(2)}
                </div>
                <p>Mean Error</p>
              </div>
              <div className="facts-card">
                <div className="card">{summaryData?.numberOfLocalities}</div>
                <p>Total Localities</p>
              </div>
              <div className="facts-card">
                <div className="card">{summaryData?.bestModel}</div>
                <p>Best Model</p>
              </div>
              <div className="facts-card">
                <div className="card">{summaryData?.totalTreatments}</div>
                <p>Total Treatments</p>
              </div>
              <div className="facts-card">
                <div className="card">{(summaryData?.avg_max_diff)}</div>
                <p>Avg Max Diff</p>
              </div>
              {/* <div className="facts-card">
                <div className="card">{summaryData?.ranking ? summaryData?.ranking : '0'}</div>
                <p>Ranking</p>
              </div>
              <div className="facts-card">
                <div className="card">{summaryData?.totalTreatments}</div>
                <p>Total Treatments</p>
              </div> */}
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default Facts;
