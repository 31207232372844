import React, { useEffect, useState } from "react";
import herobg from "../../assets/home/background/landingImage.png";
import "./home.scss";
import Button from "../common/Button";
import { localities } from "../../constants/Home";
import SelectLocailites from "./SelectLocailites/SelectLocailites";
import OverviewTable from "./OverviewTable/OverviewTable";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { panelHomeLocalities } from "../../redux/slice/PanelSlice";
import { Navigate } from "react-router-dom";
import routes from "../../config/routes";
import axios from "axios";
import api from "../../config/api";
import { localitiesDetail } from "../../api/api";
import { ClipLoader } from "react-spinners";
import Popup from "../common/Popup";
import TextField from "../common/TextField";
import Welcome from "./Welcome";

const Home = () => {
  const scrollToSection = () => {
    const section = document.getElementById("select");
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [localitiesSummaryData, setLocalitiesSummaryData] = useState(null);
  const [selectLocalitiesData, setSelectLocalitiesData] = useState([]);
  const [summaryLoader, setSummaryLoader] = useState(false);
  const [localitiesLoader, setLocalitiesLoader] = useState(false);

  const userToParse =
    useSelector((state) => state?.userAuthSlice?.user) ||
    localStorage.getItem("User");

  const user = JSON.parse(userToParse) ? JSON.parse(userToParse) : userToParse;

  const userId = user?.user_id;

  const token = useSelector((state) => state?.userAuthSlice?.token);
  const config = {
    headers: { token: `${JSON.parse(token)}` },
  };

  const dispatch = useDispatch();

  const fetchLocalities = async () => {
    setSummaryLoader(true);
    await axios
      .post(api.panel.home.localitiesSummary(userId), {}, config)
      .then((response) => {
        setLocalitiesSummaryData(response?.data);
        setSummaryLoader(false);
      })
      .catch((err) => {
        setSummaryLoader(false);
        console.log({ err });
      });
  };

  const fetchSelectLocalities = async () => {
    setLocalitiesLoader(true);
    await localitiesDetail(userId, "", config)
      .then((response) => {
        const cleanedInput = response.data.trim();
        const replacedInput = cleanedInput.replace(/NaN/g, "null");
        const localitesData = JSON.parse(replacedInput);
        setSelectLocalitiesData(localitesData);
        setLocalitiesLoader(false);
      })
      .catch((err) => {
        setLocalitiesLoader(false);
        console.log({ err });
      });
    // export const localitiesDetail = (userId, config) => axios.get(api.panel.dashboard.localityDetail(userId), config);
  };

  useEffect(() => {
    if (userId) {
      fetchLocalities();
      fetchSelectLocalities();
    }
  }, []);

  if (!user && !token) {
    return <Navigate to={routes.auth.login} />;
  }

  return (
    <>
      <section id="hero-wrapper">
        <img src={herobg} alt="hero-bg" />
      </section>
      <div className="user-name">
        <h2>
          Hello{" "}
          {user?.username === "nan nan"
            ? "User"
            : `${
                user?.firstname?.charAt(0)?.toUpperCase() +
                user?.firstname?.slice(1)
              } ${
                user?.lastname?.charAt(0)?.toUpperCase() +
                user?.lastname?.slice(1)
              }`}
        </h2>
      </div>
      <section id="localities-wrapper">
        <Button
          id="secondary-button"
          className="localities-button"
          onClick={scrollToSection}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
            >
              <path
                d="M11.5959 26.8333L13.6084 24.7729L21.6584 32.8229L21.6584 7.66663L24.5334 7.66663L24.5334 32.8229L32.6313 24.725L34.6438 26.7854L23.0959 38.3333L11.5959 26.8333Z"
                fill="#3C35B3"
              />
            </svg>
          }
        >
          Localities
        </Button>
        <h3>Localities</h3>
        <div className="new-localities-bg">
          {summaryLoader ? (
            <div className="loader">
              <ClipLoader size={40} color={"#3C35B3"} />
              <h1
                style={{
                  color: "#3C35B3",
                  fontSize: "2.4rem",
                }}
              >
                {/* &nbsp;&nbsp;&nbsp;&nbsp;Creating User... */}
              </h1>
            </div>
          ) : (
            <>
              <div className="content-wrapper">
                {localities?.map((item, index) => {
                  return (
                    <>
                      <p className="mt-4">{item?.define}</p>
                    </>
                  );
                })}
              </div>
              <div className="content-value-wrapper" id="select">
                {localities?.map((item, index) => {
                  return (
                    <>
                      <div className="localities-card">
                        {index === 0
                          ? localitiesSummaryData?.localitiesBeyondLiceLimit
                          : index === 1
                          ? localitiesSummaryData?.localitiesOverLiceLimitFiveWeeks
                          : localitiesSummaryData?.localitesRecommendedATreatment}
                      </div>
                    </>
                  );
                })}
              </div>
            </>
          )}
        </div>
        {/* <div className="localities-bg">
          {summaryLoader ? (
            <div className="loader">
              <ClipLoader size={40} color={"#3C35B3"} />
              <h1
                style={{
                  color: "#3C35B3",
                  fontSize: "2.4rem",
                }}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;Creating User...
              </h1>
            </div>
          ) : (
            <>
              {localities?.map((item, index) => {
                return (
                  <>
                    <div className="content-wrapper">
                      <div className="localities-card">
                        {index === 0
                          ? localitiesSummaryData?.localitiesBeyondLiceLimit
                          : index === 1
                          ? localitiesSummaryData?.localitiesOverLiceLimitFiveWeeks
                          : localitiesSummaryData?.localitesRecommendedATreatment}
                      </div>
                      <h4>{item?.name}</h4>
                      <p className="mt-4">{item?.define}</p>
                    </div>
                  </>
                );
              })}
            </>
          )}
        </div> */}
      </section>
      {localitiesLoader ? (
        <div className="loader">
          <ClipLoader size={40} color={"#3C35B3"} />
          <h1
            style={{
              color: "#3C35B3",
              fontSize: "2.4rem",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;Fetching Data...
          </h1>
        </div>
      ) : (
        <>
        
        <SelectLocailites
            localityData={
              selectLocalitiesData?.length !== 0 ? selectLocalitiesData : null
            }
          />
        
          <OverviewTable
            tableData={
              selectLocalitiesData?.length !== 0 ? selectLocalitiesData : null
            }
          />
        </>
      )}
    </>
  );
};

export default Home;
