import React from "react";
import "./calculatorAndEnvFacts.scss";
import TextField from "../../../common/TextField";
import Button from "../../../common/Button";
import { environmentalFacts } from "../../../../constants/Krakasen";
import { toast } from "react-toastify";

const CalculatorAndEnvFacts = ({localitySelected}) => {
  
  const handleKeyPress = (e) => {
    if (e.key === 'Tab' || e.key === 'Enter') {
      toast.info('Coming Soon.')
    }
  };


  return (
    <>
      <section id="calculator-environmental-facts">
        <div className="row">
          <div className="col-md-6">
            <div className="calculator">
              <h2>Slaughter Calculator</h2>
              <TextField placeholder="John" onKeyDown={handleKeyPress} />
              <TextField placeholder="Avg.Weight" onKeyDown={handleKeyPress}/>
              <TextField placeholder="Desired Slaughter Weightx" onKeyDown={handleKeyPress} />
              <Button id="primary-button" onClick={() => toast.info('Coming Soon.')}>Calculate</Button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="environmental-facts">
              <h2>Environmental Facts</h2>
              <div className="facts-card-wrapper">
                {environmentalFacts?.map((item, index) => {
                  return (
                    <>
                      {index < 4 && (
                        <>
                          <div className="facts-card">
                            <div className="card">{index === 0 ? localitySelected?.status : index === 1 ? (localitySelected?.mae)?.toFixed(2) : index === 2 ? localitySelected?.missed_weeks : (localitySelected?.treatment_threshold)?.toFixed(2)}</div>
                            <p>{item?.define}</p>
                          </div>
                        </>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CalculatorAndEnvFacts;
