export const krakasenLocalities = [
  {
    id: "1",
    value: "0,32",
    define: "Lice this week",
  },
  {
    id: "2",
    value: "0,61",
    define: "Lice in 5 weeks",
  },
  {
    id: "3",
    value: "21",
    define: "Week for recomended treatment",
  },
];
export const environmentalFacts = [
  {
    id: "1",
    value: "23 feb",
    define: "Last registered lice",
    factdefine: "Last time code run",
  },
  {
    id: "2",
    value: "0,024",
    define: "MAE avg ",
    factdefine: "MAE avg all loclities",
  },
  {
    id: "3",
    value: "0,051",
    define: "Max diff week",
    factdefine: "Max diff avg all localities",
  
  },
  {
    id: "4",
    value: "2",
    define: "Weeks missed by +- 0,1 lice",
    factdefine: "Weeks missed by +- 0,1 lice",
    
  },
  {
    id: "5",
    value: "NN2",
    define: "ML model used",
    factdefine: "Ranking lusenivå i Norge",
   
  },
  {
    id: "6",
    value: "2",
    define: "Tuning used",
    factdefine: "Andel av lokasjonens totale Co2 utslipp ved 1 avlusning",
   
  },
  {
    id: "7",
    value: "254",
    define: "Datapoints on locality",
    factdefine: "Ranking lusenivå i Norge",
   
  },
  {
    id: "8",
    value: "235/678",
    define: "Ranking Licelevel",
    factdefine: "Andel av lokasjonens totale Co2 utslipp ved 1 avlusning",
  },
];
