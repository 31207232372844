import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../api/api";
import { toast } from "react-toastify";

const initialState = {
  homeLocalities: [],
  panelLoader: false,
  homeLocalitiesLoader: false,
  dashboardLocalityPrediction: [],
  localitiesOverviewData: [],
};

export const panelHomeLocalities = createAsyncThunk(
  "panelHomeLocalities",
  async (userId) => {
    const response = await api.homeLocalities(userId);
    return response.data;
  }
);

export const panelDashboardLocalityPrediction = createAsyncThunk("panelDashboardLocalityPrediction", async ({ userId, search, config }) => {
  const response = await api.localitiesDetail(userId, search, config);
  const cleanedInput = response.data.trim();
  const replacedInput = cleanedInput.replace(/NaN/g, "null");
  const localitesData = JSON.parse(replacedInput);
  return localitesData;
}
);

export const getLocalitiesOverview = createAsyncThunk('getLocalitiesOverview', async ({ userId, search, config }) => {
  const response = await api.localitiesOverview(userId, search, config);
  const cleanedInput = response.data.trim();
  const replacedInput = cleanedInput.replace(/NaN/g, "null");
  const localitesData = JSON.parse(replacedInput);
  return localitesData;
})

export const updateLocalitySoldStatus = createAsyncThunk('updateLocalitySoldStatus', async ({ userId, localityNo, status, config }) => {
  const response = await api.changeLocalitySoldStatus(userId, localityNo, status, config);
  return response.data;
})

const PanelSlice = createSlice({
  name: "PanelSlice",
  initialState,

  extraReducers: (builder) => {
    //  User login Api process
    builder.addCase(panelHomeLocalities.pending, (state) => {
      state.homeLocalitiesLoader = true;
    });

    builder.addCase(panelHomeLocalities.fulfilled, (state, action) => {
      console.log({ action });
      state.homeLocalities = action?.payload?.localities;
      state.homeLocalitiesLoader = false;
    });
    builder.addCase(panelHomeLocalities.rejected, (state, action) => {
      state.homeLocalitiesLoader = false;
    });
    builder.addCase(panelDashboardLocalityPrediction.pending, (state) => {
      state.panelLoader = true;
    });

    builder.addCase(
      panelDashboardLocalityPrediction.fulfilled,
      (state, action) => {
        console.log({ action });
        state.dashboardLocalityPrediction = action?.payload;
        state.panelLoader = false;
      }
    );
    builder.addCase(
      panelDashboardLocalityPrediction.rejected,
      (state, action) => {
        state.panelLoader = false;
      }
    );

    builder
      .addCase(getLocalitiesOverview.pending, (state) => {
        state.panelLoader = true;
      })
      .addCase(getLocalitiesOverview.fulfilled, (state, action) => {
        state.panelLoader = false;
        state.localitiesOverviewData = action.payload;
      })
      .addCase(getLocalitiesOverview.rejected, (state) => {
        state.panelLoader = false;
      })
      .addCase(updateLocalitySoldStatus.pending, (state) => {
        state.panelLoader = true;
      })
      .addCase(updateLocalitySoldStatus.fulfilled, (state) => {
        state.panelLoader = false;
        toast.success('Sold status changed!')
      })
      .addCase(updateLocalitySoldStatus.rejected, (state) => {
        state.panelLoader = false;
      })
  },
});

export default PanelSlice.reducer;
