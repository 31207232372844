import React, { useEffect, useState } from "react";
import { MdWbSunny, MdBrightness3, MdAccountCircle } from "react-icons/md";

import logo from "../../assets/logokit/logo.png";

import "./header.scss";
import { profileMenu } from "../../constants/Home";
import { Link, NavLink } from "react-router-dom";
import routes from "../../config/routes";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/slice/AuthSlice";
import AdminHeaderOptions from "../../constants/AdminHeaderOptions";
import SupervisorHeaderOptions from "../../constants/SupervisorHeaderOptions";
import { localitiesDetail } from "../../api/api";

const Header = ({ onclick, click }) => {
  const [isHeaderActive, setHeaderActive] = useState(false);
  const [selectLocalitiesData, setSelectLocalitiesData] = useState([]);

  const dispatch = useDispatch();

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setHeaderActive(true);
    } else {
      setHeaderActive(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const mode = localStorage.getItem("modeName");

  const userToParse =
    useSelector((state) => state?.userAuthSlice?.user) ||
    localStorage.getItem("User");

  const user = JSON.parse(userToParse) ? JSON.parse(userToParse) : userToParse;

  const username = user ? user?.username?.split(" ") : "";
  const userId = user?.user_id;

  const token = useSelector((state) => state?.userAuthSlice?.token);
  const config = {
    headers: { token: `${JSON.parse(token)}` },
  };

  const fetchSelectLocalities = async () => {
    await localitiesDetail(userId, "", config)
      .then((response) => {
        const cleanedInput = response.data.trim();
        const replacedInput = cleanedInput.replace(/NaN/g, "null");
        const localitesData = JSON.parse(replacedInput);
        setSelectLocalitiesData(localitesData);
        localStorage.setItem("locality", localitesData[0]?.localityNo);
      })
      .catch((err) => {
        console.log({ err });
      });
    // export const localitiesDetail = (userId, config) => axios.get(api.panel.dashboard.localityDetail(userId), config);
  };

  const localityToUse = localStorage.getItem("locality");

  useEffect(() => {
    fetchSelectLocalities();
  }, []);

  const hamburgerShow = window.location.pathname.includes("/locality") || window.location.pathname.includes("/setting");


  return (
    <>
      <section
        id="header-wrapper"
        className={` ${isHeaderActive ? "header-wrapper-scroll" : ""} ${
          window.location.pathname === "/home" ? " " : "header-wrapper"
        }`}
      >
        { hamburgerShow && (
          <div id="hamburger-icon" onClick={click}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
            >
              <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" />
            </svg>
          </div>
        )}

        {user?.level === "admin" && (
          <Link to={routes.panel.admin.facts}>
            <img src={logo} alt="logo" />
          </Link>
        )}
        {user?.level === "superior" && (
          <Link to={routes.panel.superior.facts}>
            <img src={logo} alt="logo" />
          </Link>
        )}
        {user?.level === "user" && (
          <Link to={routes?.panel?.user?.home}>
            <img src={logo} alt="logo" />
          </Link>
        )}

        <div className="user-details">
          <input
            type="checkbox"
            class="checkbox"
            id="checkbox"
            onChange={onclick}
            // value={mode}
            checked={mode !== "dark" ? true : false}
          />
          <label for="checkbox" class="checkbox-label">
            <MdBrightness3 className="moon" size={30} />
            <MdWbSunny className="sun" size={30} />
            <span class="ball"></span>
          </label>
          <div className="dropdown">
            <div className="user-profile">
              <p>
                {user?.username === "nan nan"
                  ? "User"
                  : user?.username.charAt(0).toUpperCase() +
                    user?.username.slice(1)}
                {}
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="15"
                viewBox="0 0 16 15"
                fill="none"
              >
                <path
                  d="M7.66667 7.03704C6.6125 7.03704 5.75 6.70864 5.07917 6.05185C4.40833 5.39506 4.07292 4.55062 4.07292 3.51852C4.07292 2.48642 4.40833 1.64198 5.07917 0.985185C5.75 0.328395 6.6125 0 7.66667 0C8.72083 0 9.58333 0.328395 10.2542 0.985185C10.925 1.64198 11.2604 2.48642 11.2604 3.51852C11.2604 4.55062 10.925 5.39506 10.2542 6.05185C9.58333 6.70864 8.72083 7.03704 7.66667 7.03704ZM0 14.5667V12.3617C0 11.7675 0.151736 11.2593 0.455208 10.837C0.758681 10.4148 1.15 10.0942 1.62917 9.87531C2.69931 9.40617 3.72552 9.05432 4.70781 8.81975C5.6901 8.58519 6.67639 8.4679 7.66667 8.4679C8.65695 8.4679 9.63924 8.5891 10.6135 8.83148C11.5878 9.07387 12.6094 9.42333 13.6783 9.87986C14.1783 10.1008 14.5791 10.4211 14.8808 10.8408C15.1825 11.2605 15.3333 11.7675 15.3333 12.3617V14.5667H0ZM1.4375 13.1593H13.8958V12.3617C13.8958 12.1115 13.82 11.873 13.6682 11.6463C13.5165 11.4195 13.3288 11.2514 13.1052 11.142C12.083 10.6572 11.1486 10.3249 10.3021 10.1451C9.45556 9.96523 8.57708 9.87531 7.66667 9.87531C6.75625 9.87531 5.86979 9.96523 5.00729 10.1451C4.14479 10.3249 3.21042 10.6572 2.20417 11.142C1.98056 11.2514 1.79688 11.4195 1.65313 11.6463C1.50938 11.873 1.4375 12.1115 1.4375 12.3617V13.1593ZM7.66667 5.62963C8.28958 5.62963 8.80469 5.43025 9.21198 5.03148C9.61927 4.63272 9.82292 4.1284 9.82292 3.51852C9.82292 2.90864 9.61927 2.40432 9.21198 2.00556C8.80469 1.60679 8.28958 1.40741 7.66667 1.40741C7.04375 1.40741 6.52865 1.60679 6.12135 2.00556C5.71406 2.40432 5.51042 2.90864 5.51042 3.51852C5.51042 4.1284 5.71406 4.63272 6.12135 5.03148C6.52865 5.43025 7.04375 5.62963 7.66667 5.62963Z"
                  fill="white"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="7"
                viewBox="0 0 11 7"
                fill="none"
                className="arrow"
              >
                <path
                  d="M5.5 7L0 1.0636L0.985417 0L5.5 4.89753L10.0146 0.024735L11 1.08834L5.5 7Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className="menu">
              {user?.level === "admin" && (
                <>
                  {AdminHeaderOptions?.map((item, index) => {
                    return (
                      <>
                        {index === 0 ? (
                          <NavLink
                            to={`/admin/locality/${
                              selectLocalitiesData.length !== 0
                                ? selectLocalitiesData[0]?.localityNo
                                : localityToUse
                            }`}
                            className={`${
                              window.location.pathname.includes(
                                "admin/locality"
                              )
                                ? "menu-item active"
                                : "menu-item"
                            }`}
                          >
                            <div className="icon">{item?.icon}</div>
                            <a>{item?.name}</a>
                          </NavLink>
                        ) : (
                          <NavLink to={item?.path} className="menu-item">
                            <div className="icon">{item?.icon}</div>
                            <a>{item?.name}</a>
                          </NavLink>
                        )}
                      </>
                    );
                  })}
                </>
              )}
              {user?.level === "superior" && (
                <>
                  {SupervisorHeaderOptions?.map((item, index) => {
                    return (
                      <>
                        {index === 0 ? (
                          <NavLink
                            to={`/superior/locality/${
                              selectLocalitiesData.length !== 0
                                ? selectLocalitiesData[0]?.localityNo
                                : localityToUse
                            }`}
                            className={`${
                              window.location.pathname.includes(
                                "superior/locality"
                              )
                                ? "menu-item active"
                                : "menu-item"
                            }`}
                          >
                            <div className="icon">{item?.icon}</div>
                            <a>{item?.name}</a>
                          </NavLink>
                        ) : (
                          <NavLink to={item?.path} className="menu-item">
                            <div className="icon">{item?.icon}</div>
                            <a>{item?.name}</a>
                          </NavLink>
                        )}
                      </>
                    );
                  })}
                </>
              )}
              {user?.level === "user" && (
                <>
                  {profileMenu?.map((item, index) => {
                    return (
                      <>
                        {index === 1 ? (
                          <NavLink
                            to={`/user/locality/${selectLocalitiesData[0]?.localityNo}`}
                            className={`${
                              window.location.pathname.includes("user/locality")
                                ? "menu-item active"
                                : "menu-item"
                            }`}
                          >
                            <div className="icon">{item?.icon}</div>
                            <a>{item?.name}</a>
                          </NavLink>
                        ) : (
                          <NavLink to={item?.path} className="menu-item">
                            <div className="icon">{item?.icon}</div>
                            <a>{item?.name}</a>
                          </NavLink>
                        )}
                      </>
                    );
                  })}
                </>
              )}
              <div className="menu-item" onClick={() => dispatch(logout())}>
                <div className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                  >
                    <path
                      d="M3.5625 16.625C3.24583 16.625 2.96875 16.5062 2.73125 16.2688C2.49375 16.0312 2.375 15.7542 2.375 15.4375V3.5625C2.375 3.24583 2.49375 2.96875 2.73125 2.73125C2.96875 2.49375 3.24583 2.375 3.5625 2.375H9.48021V3.5625H3.5625V15.4375H9.48021V16.625H3.5625ZM13.1812 12.9635L12.3302 12.1125L14.349 10.0938H7.125V8.90625H14.3094L12.2906 6.8875L13.1417 6.03646L16.625 9.51979L13.1812 12.9635Z"
                      fill="#666666"
                    />
                  </svg>
                </div>
                <a>Logout</a>
              </div>
              <div className="mob-toggle-btn">
                <input
                  type="checkbox"
                  class="checkbox"
                  id="checkbox"
                  onChange={onclick}
                  // value={mode}
                  checked={mode !== "dark" ? true : false}
                />
                <label for="checkbox" class="checkbox-label">
                  <MdBrightness3 className="moon" size={30} />
                  <MdWbSunny className="sun" size={30} />
                  <span class="ball"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
