import React from 'react'
import './language.scss'
import Button from '../../common/Button'
import { Navigate } from 'react-router-dom'
import routes from '../../../config/routes'
import { useSelector } from 'react-redux'

const Language = () => {

  const userToParse = useSelector((state) => state?.userAuthSlice?.user) || localStorage.getItem('User');

  const token = useSelector((state) => state?.userAuthSlice?.token) || localStorage.getItem('Token');

  const user = JSON.parse(userToParse) ? JSON.parse(userToParse) : userToParse;
  const userId = user?.user_id;

  if (user && token) {
    if (user?.level === 'admin' && user?.infoFilled !== 'True') {
      return <Navigate to={routes.panel.admin.isInfoFilled} />
    }
    if (user?.level === 'superior' && user?.infoFilled !== 'True') {
      return <Navigate to={routes.panel.superior.isInfoFilled} />
    }
    if (user?.level === 'user' && user?.infoFilled !== 'True') {
      return <Navigate to={routes.panel.user.isInfoFilled} />
    }
  }

  return (
    <section id='language-wrapper'>
      <h2>Change Language</h2>
      <div className="row">
        <div className="col-8">
          <div className="col-12">
            <Button id="primary-button" className="language">English</Button>
          </div>
          <div className="col-12">
            <Button id="secondary-button" className="language-change">Norwegian</Button>
          </div>
          <Button id="primary-button" className="save-changing">
            Save Changes
          </Button>
        </div>
      </div>

    </section>
  )
}

export default Language
