const routes = {
  error: "/404",

  auth: {
    login: "/",
    register: "/register",
  },

  panel: {
    admin: {
      dashboard: "/admin/dashboard",
      localities: `/admin/locality/:id`,
      settings: {
        generalInfo: "/admin/setting/general-Info",
        password: "/admin/setting/password",
        language: "/admin/setting/language",
      },
      facts: "/admin/facts",
      userDataFacts: "/admin/facts/user-data",
      isInfoFilled: '/admin/info-filled',
    },

    superior: {
      localities: `/superior/locality/:id`,
      settings: {
        generalInfo: "/superior/setting/general-Info",
        password: "/superior/setting/password",
        language: "/superior/setting/language",
      },
      facts: "/superior/facts",
      userDataFacts: "/superior/facts/user-data",
      isInfoFilled: '/superior/info-filled',
    },

    user: {
      home: "/user/home",
      localities: `/user/locality/:id`,
      setting: {
        generalInfo: "/user/setting/general-Info",
        password: "/user/setting/password",
        language: "/user/setting/language",
      },
      isInfoFilled: '/user/info-filled',
    },
  },
};

export default routes;
