import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useLocation } from "react-router-dom";

const Popup = ({ children, handleCancle, id, className }) => {
  const popupRef = useRef(null);
  const location = useLocation();
  
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (popupRef.current && !popupRef.current.contains(e.target)) {
        handleCancle();
      }
    };
    if (location?.pathname === "/admin/facts/user-data" || location?.pathname === '/superior/facts/user-data') {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.classList.add("body-fixed");
    } else {
      document.addEventListener("mousedown", handleClickOutside);
      document.body.classList.add("body-fixed");
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.classList.remove("body-fixed");
    };
  }, [handleCancle]);
  return (
    <section id="popups-wrapper" className={className}>
      <div className="popups" ref={popupRef}>
        <div className="close" onClick={handleCancle}>
          <div className="close-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M1.78125 17.5312L0.46875 16.2188L7.6875 9L0.46875 1.78125L1.78125 0.46875L9 7.6875L16.2188 0.46875L17.5312 1.78125L10.3125 9L17.5312 16.2188L16.2188 17.5312L9 10.3125L1.78125 17.5312Z"
                fill="#3C35B3"
              />
            </svg>
          </div>
        </div>
        <div className="popup-content">{children}</div>
      </div>
    </section>
  );
};

export default Popup;
