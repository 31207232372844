import React, { useEffect, useState } from "react";
import "./selectLocailites.scss";
import { selectLocaltiy } from "../../../constants/Home";
import { useDispatch, useSelector } from "react-redux";
import { ClimbingBoxLoader, DotLoader } from "react-spinners";
import { NavLink, useNavigate } from "react-router-dom";
import '../../../'

const SelectLocailites = ({ localityData }) => {
  const [showHoverValue, setShowHoverValue] = useState(false);
  const userToParse = useSelector((state) => state?.userAuthSlice?.user);
  const navigate = useNavigate();
  const [selectedLocalitiesActive, setSelectedLocalitiesActive] = useState(false)

  const user = JSON.parse(userToParse) ? JSON.parse(userToParse) : userToParse;

  const [myList, setMyList] = useState([]);

  const homeLocalitiesLoader = useSelector((state) => state?.PanelSlice?.homeLocalitiesLoader);
  const dataToShow = [];

  const images = Array.from({ length: 41 }, (_, index) => import(`../../../assets/home/images/Picture_${index + 1}.jpg`));
  console.log({images})

  // useEffect(() => {
  //   let list = [];
  //   if (localityData !== null && localityData?.length !== 0) {
  //     for (const item of localityData) {
  //       if (!dataToShow.includes(item?.localityNo)) {
  //         dataToShow.push(item?.localityNo);
  //         list = [...list, item]
  //         setMyList(list)
  //       }
  //     }
  //   }

  // }, [localityData])

  return (
    <section id="select-locailites">
      <h4>Select Locality</h4>
      {
        homeLocalitiesLoader ?
          <div
            className="col-lg-12 col-md-12 col-sm-12 col-12"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflowY: 'hidden'
            }}>
            <ClimbingBoxLoader color={"#3C35B3"} />
            <h1
              style={{
                color: "#3C35B3",
                fontSize: "2.4rem",
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;Please Wait...
            </h1>
          </div>
          :
          <div className="locailty-wrapper">
            {localityData?.map((item, index) => {
              return (
                <>
                  {
                    <>
                      {/* desktop view */}
                      <NavLink to={`/user/locality/${item?.localityNo}`} className="locailty mob-hide">
                        <span>
                          <img src={selectLocaltiy[index]?.src} alt="" />
                        </span>
                        <p>
                          {item?.localityName}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                          >
                            <path
                              d="M23.3333 29.9167L21.5416 28.1667L28.5416 21.1667L6.66663 21.1667V18.6667L28.5416 18.6667L21.5 11.625L23.2916 9.875L33.3333 19.9167L23.3333 29.9167Z"
                              fill="white"
                            />
                          </svg>
                        </p>
                        <div className="hover-value">
                          <div className="value">
                            <span>{(item?.latest_lice_value)?.toFixed(2)}</span>
                            <p>Lice this week</p>
                          </div>
                          <div className="value">
                            <span>{(item?.pred_5th_value)?.toFixed(2)}</span>
                            <p>Lice in 5 weeks</p>
                          </div>
                          <div className="value">
                            <span>{item?.week_for_treatment === -1 ? '0' : item?.week_for_treatment}</span>
                            <p>Week for recommended treatment</p>
                          </div>
                        </div>
                      </NavLink>
                      {/* mobile view */}
                      <div className={ `locailty mob-show ${selectedLocalitiesActive ? "selected-locaility-active" : ""}`}>
                        <span onClick={()=>setSelectedLocalitiesActive(true)}>
                          <img src={selectLocaltiy[index]?.src} alt={index + 1} />
                        </span>
                        <p className="mob-para"  >
                          {item?.localityName}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            onClick={()=>navigate(`/user/locality/${item?.localityNo}`)}
                          >
                            <path
                              d="M23.3333 29.9167L21.5416 28.1667L28.5416 21.1667L6.66663 21.1667V18.6667L28.5416 18.6667L21.5 11.625L23.2916 9.875L33.3333 19.9167L23.3333 29.9167Z"
                              fill="white"
                            />
                          </svg>
                        </p>
                        <div className="hover-value" >
                          <div className="value">
                            <span>{(item?.latest_lice_value)?.toFixed(2)}</span>
                            <p>Lice this week</p>
                          </div>
                          <div className="value">
                            <span>{(item?.pred_5th_value)?.toFixed(2)}</span>
                            <p>Lice in 5 weeks</p>
                          </div>
                          <div className="value">
                            <span>{item?.week_for_treatment === -1 ? '0' : item?.week_for_treatment}</span>
                            <p>Week for recommended treatment</p>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                </>
              );
            })}
          </div>
      }
    </section>
  );
};

export default SelectLocailites;
