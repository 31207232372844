import axios from "axios";
import api from "../config/api";

//auth
export const loginUser = (payload) => axios.post(api.auth.login, payload);

//panel
//home
export const homeLocalities = (userId) => axios.get(api.panel.home.homeLocalities(userId));

//localities overview
export const localitiesOverview = (userId, search, config) => axios.get(api.panel.home.localitiesOverview(userId, search), config);
export const changeLocalitySoldStatus = (userId, localityNo, status, config) => axios.post(api.panel.locality.changeLocalitySoldStatus(userId, localityNo, status), {}, config);

//dashboard
export const dashboardPredictions = (userId) => axios.get(api.panel.dashboard.dashboardPredictions(userId));
export const localitiesDetail = (userId, search, config) => axios.get(api.panel.dashboard.localityDetail(userId, search), config);

export const summaryDetails = (userId, config) => axios.get(api.panel.dashboard.summaryDetails(userId), config);
export const getAllUsers = (search, config) => axios.get(api.panel.dashboard.getAllUsers(search), config);

//alluser
export const localitiesAllUser = () => axios.get(api.panel.locality.allUser);

///alllocalities
export const dataAllLocalities = () => axios.get(api.panel.locality.allLocalities);

//settings
export const updatePersonalInfo = (userId, payload, config) => axios.post(api.panel.settings.updatePersonalInfo(userId), payload, config);
export const updatePassword = (userId, payload, config) => axios.post(api.panel.settings.updatePassword(userId), payload, config);

//get-user-detail
export const getUserDetails = (userId, config) => axios.get(api.auth.getUserDetails(userId), config);
