import React from "react";
import { Navigate } from "react-router-dom";
import routes from "../config/routes";
import { useSelector } from "react-redux";

const UserRoute = ({ children }) => {

    const userToParse = useSelector((state) => state?.userAuthSlice?.user) || localStorage?.getItem('User');
    const token = useSelector((state) => state?.userAuthSlice?.token);

    const user = JSON.parse(userToParse) ? JSON.parse(userToParse) : userToParse;

    //user role is missing here. add it here too. // compare the routes used in CMS project.

    return (
        <>
            {
                (user && token && user?.level === 'user') ? children : !(user && token) ? <Navigate to={routes.auth.login} /> : (user && token && user?.level === 'admin') ? <Navigate to={routes.panel.admin.facts} /> :  <Navigate to={routes.panel.superior.facts} />
            }
        </>
    );
};

export default UserRoute;
