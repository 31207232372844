import React, { useEffect } from "react";
import "./chart.scss";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useDispatch, useSelector } from "react-redux";
import { panelDashboardLocalityPrediction } from "../../../../redux/slice/PanelSlice";

const Chart = ({ selectedData }) => {
  const userToParse = useSelector((state) => state?.userAuthSlice?.user);

  const user = JSON.parse(userToParse) ? JSON.parse(userToParse) : userToParse;

  const userId = user?.user_id;

  const loycalityPredictionList = useSelector(
    (state) => state?.PanelSlice?.dashboardLocalityPrediction
  );

  // const formattedStr = selectedData?.preds.replace(/([\d.]+)(\s|$)/g, '$1,').replace(/,\s*$/, '');
  // const nlist = JSON.parse(`[${formattedStr}]`);
  // const nList = JSON.parse()
  // const list = selectedData ? selectedData?.preds
  //   ?.slice(1, -1)
  //   ?.split(' ')
  //   ?.map(Number) : null;

  const withList = selectedData ? selectedData?.future_values
    ?.slice(1, -1)
    ?.split(' ')
    ?.map(Number) : null;

  const list = selectedData ? JSON.parse(selectedData?.preds_with_decay) : null;

  const data = [
    { name: "Week 1", prediction: Number(list !== null ? list[0].toFixed(2) : 0) },
    { name: "Week 2", prediction: Number(list !== null ? list[1].toFixed(2) : 0) },
    { name: "Week 3", prediction: Number(list !== null ? list[2].toFixed(2) : 0) },
    { name: "Week 4", prediction: Number(list !== null ? list[3].toFixed(2) : 0) },
    { name: "Week 5", prediction: Number(list !== null ? list[4].toFixed(2) : 0) },
  ];

  const withData = [
    { name: "Week 1", prediction: Number(withList !== null ? withList[0].toFixed(2) : 0) },
    { name: "Week 2", prediction: Number(withList !== null ? withList[1].toFixed(2) : 0) },
    { name: "Week 3", prediction: Number(withList !== null ? withList[2].toFixed(2) : 0) },
    { name: "Week 4", prediction: Number(withList !== null ? withList[3].toFixed(2) : 0) },
    { name: "Week 5", prediction: Number(withList !== null ? withList[4].toFixed(2) : 0) },
  ]

  const reversedData = data.slice().reverse();
  return (
    <section id="chart-wrapper">
      <div className="row">
        <div className="col-md-6 ">
          <div className="chart space">
            <h3>
              Prediction <span> without treatment</span>
            </h3>
            <div className="pr-5">
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={data !== undefined ? data : 0} >
                  <CartesianGrid strokeDasharray="3,3" />
                  <XAxis dataKey="name" />
                  <YAxis orientation="left" className="custom-y-axis" />
                  <Tooltip />
                  <defs>
                    <linearGradient
                      id="gradient"
                      x1="0%"
                      y1="0%"
                      x2="0%"
                      y2="100%"
                    >
                      <stop
                        offset="0%"
                        style={{ stopColor: "#3c35b3", stopOpacity: 1 }}
                      />
                      <stop
                        offset="100%"
                        style={{ stopColor: "#3fa1db", stopOpacity: 1 }}
                      />
                    </linearGradient>
                  </defs>
                  <Bar
                    dataKey="prediction"
                    // fill="#3C35B3"
                    fill="url(#gradient)"
                    radius={[10, 10, 0, 0]}
                  />
                </BarChart >
              </ResponsiveContainer>
            </div>
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="chart">
            <h3>
              No <span>recommended treatment</span>
            </h3>
            <div className="pl-5">
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={withData}>
                  <CartesianGrid strokeDasharray="3,3" />
                  <XAxis dataKey="name" />
                  <YAxis orientation="right" className="custom-y-axis" />
                  <Tooltip />
                  <defs>
                    <linearGradient
                      id={selectedData?.week_for_treatment === -1 ? 'gradients' : '"gradient"'}
                      x1="0%"
                      y1="0%"
                      x2="0%"
                      y2="100%"
                    >
                      <stop
                        offset="0%"
                        style={{ stopColor: selectedData?.week_for_treatment === -1 ? '#3c35b3b8' : "#3c35b3", stopOpacity: 1 }}
                      />
                      <stop
                        offset="100%"
                        style={{ stopColor: selectedData?.week_for_treatment === -1 ? '#3fa1dbb5' : "#3fa1db", stopOpacity: 1 }}
                      />
                    </linearGradient>
                  </defs>
                  <Bar
                    dataKey="prediction"
                    fill={
                      selectedData?.week_for_treatment === -1
                        ? 
                        "url(#gradients)"
                        : "url(#gradient)"
                    }
                    radius={[10, 10, 0, 0]}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Chart;
