import React from "react";
import logo from "../../assets/logokit/logo.png";
import "./footer.scss";

const Footer = () => {
  return (
    <section id="footer-wrapper">
      <div className="row">
        <div className="col-md-4">
          <img src={logo} alt="logo" />
        </div>
        <div className="col-md-4">
          <div>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="19"
                viewBox="0 0 24 19"
                fill="none"
              >
                <path
                  d="M1.8 19C1.32 19 0.9 18.8219 0.54 18.4656C0.18 18.1094 0 17.6938 0 17.2188V1.78125C0 1.30625 0.18 0.890625 0.54 0.534375C0.9 0.178125 1.32 0 1.8 0H22.2C22.68 0 23.1 0.178125 23.46 0.534375C23.82 0.890625 24 1.30625 24 1.78125V17.2188C24 17.6938 23.82 18.1094 23.46 18.4656C23.1 18.8219 22.68 19 22.2 19H1.8ZM12 10.0344L1.8 3.41406V17.2188H22.2V3.41406L12 10.0344ZM12 8.25313L22.08 1.78125H1.95L12 8.25313ZM1.8 3.41406V1.78125V17.2188V3.41406Z"
                  fill="white"
                />
              </svg>
            </span>
            <a href="mailto:atle@anlice.no">atle@anlice.no</a>
          </div>
        </div>
        <div className="col-md-4">
          <div>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
              >
                <path
                  d="M17.3111 9.32871C17.2231 7.20296 16.4403 5.40222 14.9625 3.92649C13.4847 2.45076 11.6815 1.66898 9.55278 1.58114V0C10.8194 0.0351364 12.0113 0.303051 13.1285 0.803745C14.2456 1.30444 15.2264 1.97642 16.0708 2.81969C16.9153 3.66297 17.5882 4.6424 18.0896 5.75798C18.591 6.87356 18.8593 8.0638 18.8944 9.32871H17.3111ZM12.8514 9.32871C12.7634 8.4503 12.4116 7.70804 11.7958 7.10194C11.1801 6.49584 10.4324 6.14887 9.55278 6.06103V4.47989C10.8722 4.56773 11.9938 5.06842 12.9174 5.98197C13.841 6.89552 14.3468 8.0111 14.4347 9.32871H12.8514ZM17.8125 19C15.7718 19 13.6914 18.5081 11.5715 17.5243C9.45162 16.5405 7.48565 15.1438 5.67361 13.3343C3.86157 11.5247 2.46296 9.56149 1.47778 7.44452C0.492593 5.32755 0 3.25012 0 1.21221C0 0.873385 0.113094 0.591038 0.339282 0.365164C0.56547 0.139289 0.848209 0.0263523 1.1875 0.0263523H4.88194C5.12824 0.0263523 5.33935 0.114193 5.51528 0.289875C5.6912 0.465557 5.81435 0.68516 5.88472 0.948682L6.59535 4.25961C6.63178 4.51187 6.62801 4.73902 6.58403 4.94105C6.54005 5.14309 6.44567 5.31636 6.3009 5.46088L3.66806 8.1165C4.12546 8.88951 4.60926 9.6098 5.11944 10.2774C5.62963 10.945 6.19259 11.5774 6.80833 12.1748C7.45926 12.8423 8.14537 13.4528 8.86667 14.0062C9.58796 14.5596 10.3444 15.0472 11.1361 15.4688L13.6431 12.8863C13.819 12.693 14.0226 12.5613 14.254 12.491C14.4853 12.4207 14.7127 12.4031 14.9361 12.4383L18.0764 13.1234C18.3403 13.1937 18.5602 13.3346 18.7361 13.5462C18.912 13.7578 19 13.9946 19 14.2566V17.8141C19 18.153 18.8869 18.4353 18.6607 18.6612C18.4345 18.8871 18.1518 19 17.8125 19ZM2.87639 6.66713L5.01389 4.50624L4.40694 1.60749H1.58333C1.61852 2.34535 1.73727 3.12275 1.93958 3.93967C2.1419 4.75659 2.45417 5.66574 2.87639 6.66713ZM12.6139 16.233C13.3352 16.5668 14.1181 16.8391 14.9625 17.0499C15.8069 17.2607 16.625 17.3837 17.4167 17.4189V14.5992L14.6986 14.0458L12.6139 16.233Z"
                  fill="white"
                />
              </svg>
            </span>
            <a href="tel:+4797069019">+47 970 69 019</a>
          </div>
        </div>
        {/* <div className="col-md-3">
          <div>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="21"
                viewBox="0 0 15 21"
                fill="none"
              >
                <path
                  d="M7.5 15.2775C9.425 13.8099 10.8785 12.3974 11.8604 11.04C12.8424 9.68249 13.3333 8.4175 13.3333 7.245C13.3333 6.2125 13.1343 5.3375 12.7361 4.62C12.338 3.9025 11.8471 3.3173 11.2636 2.8644C10.6801 2.41148 10.048 2.08336 9.36722 1.88002C8.68646 1.67667 8.06406 1.575 7.5 1.575C6.93594 1.575 6.31354 1.67667 5.63278 1.88002C4.95202 2.08336 4.31988 2.41148 3.73636 2.8644C3.15286 3.3173 2.66204 3.9025 2.26389 4.62C1.86574 5.3375 1.66667 6.2125 1.66667 7.245C1.66667 8.4175 2.15764 9.68249 3.13958 11.04C4.12153 12.3974 5.575 13.8099 7.5 15.2775ZM7.5 17.2725C4.96296 15.47 3.0787 13.7445 1.84722 12.096C0.615741 10.4475 0 8.83268 0 7.25148C0 6.05716 0.226852 5.00937 0.680556 4.10812C1.13426 3.20687 1.72222 2.45 2.44444 1.8375C3.16667 1.225 3.97204 0.765625 4.86056 0.459375C5.74907 0.153125 6.6287 0 7.49944 0C8.37019 0 9.25 0.153125 10.1389 0.459375C11.0278 0.765625 11.8333 1.225 12.5556 1.8375C13.2778 2.45 13.8657 3.20716 14.3194 4.10899C14.7731 5.01082 15 6.06014 15 7.25697C15 8.84149 14.3843 10.4562 13.1528 12.1012C11.9213 13.7462 10.037 15.47 7.5 17.2725ZM7.50247 8.925C8.03786 8.925 8.49537 8.74485 8.875 8.38454C9.25463 8.02425 9.44444 7.59112 9.44444 7.08516C9.44444 6.57922 9.25381 6.14687 8.87253 5.78813C8.49127 5.42937 8.03294 5.25 7.49753 5.25C6.96214 5.25 6.50463 5.43015 6.125 5.79046C5.74537 6.15075 5.55556 6.58388 5.55556 7.08984C5.55556 7.59578 5.74619 8.02812 6.12747 8.38688C6.50873 8.74562 6.96706 8.925 7.50247 8.925ZM0 21V19.425H15V21H0Z"
                  fill="white"
                />
              </svg>
            </span>
            <a href="https://maps.app.goo.gl/3TWXvXrnUMf6XjLw6" target="_blank">Norway </a>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default Footer;
