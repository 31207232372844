import { configureStore } from "@reduxjs/toolkit";
import userAuthSlice from "../slice/AuthSlice";
import PanelSlice from "../slice/PanelSlice";
import LocalitySlice from "../slice/LocalitySlice";
import SettingSlice from "../slice/SettingSlice";

const store = configureStore({
  reducer: {
    userAuthSlice,
    PanelSlice,
    LocalitySlice,
    SettingSlice,
  },
});

export default store;
