import React from "react";

const Button = ({ disabled, className, onClick, name, children,id, icon, featureIcon }) => {
  return (
    <>
      <div id={id}  className={className}>
        
        <button
          type="button"
          autoComplete="nope"
          disabled={disabled}
          onClick={onClick}
          name={name}
        ><span className="feature" style={{display:featureIcon?"":"none"}}>{featureIcon}</span>
          {children}
        <span className="icon" style={{display:icon?"":"none"}}>{icon}</span>
        </button>
      </div>
    </>
  );
};

export default Button;
