import { useState } from "react";

const useNewPassword = (validate) => {

  const [values, setValues] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const getErrors = (e) => {
    e.preventDefault();
    setErrors(Object.assign({}, validate(values)));
  };

  return { handleChange, values, getErrors, errors };
};

export default useNewPassword;
