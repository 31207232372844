import routes from "../config/routes";

const AdminSettingMenu = [
  {
    id: "1",
    path: routes?.panel?.admin?.settings?.generalInfo,
    name: "General info",
  },
  {
    id: "2",
    path: routes?.panel?.admin?.settings?.password,
    name: "Password",
  },
  {
    id: "3",
    path: routes?.panel?.admin?.settings?.language,
    name: "Language",
  },
];

export default AdminSettingMenu;

