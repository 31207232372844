const Host = "https://anlice.no/app";

const api = {
  test: {
    test: `${Host}/all_users`,
  },
  auth: {
    login: `${Host}/login`,
    getUserDetails: (userId) => `${Host}/getUserDetails/${userId}`
  },

  panel: {
    infoFilled: (userId) => `${Host}/userInfoFilled/${userId}`,
    editUser: (userId) => `${Host}/updateUserInfoByAdmin/${userId}`,

    home: {
      homeLocalities: (userId) => `${Host}/localities/${userId}`,
      localitiesOverview: (userId, search) => `${Host}/userLocalities/${userId}?search=${search}`,
      localitiesSummary: (userId) => `${Host}/userLocalitySummary/${userId}`,
    },

    dashboard: {
      dashboardPredictions: (userId) => `${Host}/predictions/${userId}`,
      localityDetail: (userId, search) => `${Host}/userLocalities/${userId}?search=${search}`,

      summaryDetails: (userId) => `${Host}/adminLocalitySummary/${userId}`,
      getAllUsers: (search) => `${Host}/getAllUsers?search=${search}`,

    },
    locality: {
      allUser: `${Host}/all_users`,
      allLocalities: `${Host}/all_localities`,
      createuser: `${Host}/createUser`,
      changeLocalitySoldStatus: (userId, localityNo, status) => `${Host}/changeSold/${userId}?localityNo=${localityNo}&status=${status}`,
    },

    settings: {
      updatePersonalInfo: (userId) => `${Host}/updateUserPersonalInfo/${userId}`,
      updatePassword: (userId) => `${Host}/updateUserPassword/${userId}`,
    }
  },
};

export default api;
