import React, { useEffect, useState } from "react";
import { MdSearch } from "react-icons/md";
import Button from "../../common/Button";
import { DotLoader } from "react-spinners";
import { overViewTableHeadData } from "../../../constants/Home";
import {
  getLocalitiesOverview,
  updateLocalitySoldStatus,
} from "../../../redux/slice/PanelSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import api from "../../../config/api";
import { toast } from "react-toastify";
import { Link, Navigate } from "react-router-dom";
import routes from "../../../config/routes";

const LocalitiesOverview = () => {
  const [soldStatusLoader, setSoldStatusLoader] = useState(false);

  const dispatch = useDispatch();

  const panelLoader = useSelector((state) => state?.PanelSlice?.panelLoader);

  const userToParse = useSelector((state) => state?.userAuthSlice?.user);

  const user = JSON.parse(userToParse) ? JSON.parse(userToParse) : userToParse;
  const userId = user?.user_id;

  const token = useSelector((state) => state?.userAuthSlice?.token);
  const config = {
    headers: { token: `${JSON.parse(token)}` },
  };

  useEffect(() => {
    // dispatch(getAllUsersData(config));
    dispatch(
      getLocalitiesOverview({
        userId,
        search: "",
        config,
      })
    );
  }, []);

  const localitiesOverviewData = useSelector(
    (state) => state?.PanelSlice?.localitiesOverviewData
  );

  const handleStatusChange = async (localityNo, status) => {
    setSoldStatusLoader(true);
    if (status === "no" || status === "No") {
      const updateStatus = "yes";

      await axios
        .post(
          api.panel.locality.changeLocalitySoldStatus(
            userId,
            localityNo,
            updateStatus
          ),
          {},
          config
        )
        .then((res) => {
          setSoldStatusLoader(false);
          toast.success("Sold status changed!");
          dispatch(
            getLocalitiesOverview({
              userId,
              search: "",
              config,
            })
          );
        })
        .catch((err) => {
          setSoldStatusLoader(false);
        });
    }
    if (status === "yes" || status === "Yes") {
      const updateStatus = "no";
      await axios
        .post(
          api.panel.locality.changeLocalitySoldStatus(
            userId,
            localityNo,
            updateStatus
          ),
          {},
          config
        )
        .then((res) => {
          setSoldStatusLoader(false);
          toast.success("Sold status changed!");
          dispatch(
            getLocalitiesOverview({
              userId,
              search: "",
              config,
            })
          );
        })
        .catch((err) => {
          setSoldStatusLoader(false);
        });
      // dispatch(
      //     updateLocalitySoldStatus({
      //         userId,
      //         localityNo,
      //         status: updateStatus,
      //         config,
      //     })
      // );
    }
    setSoldStatusLoader(false);
  };

  if (user && token) {
    if (user?.level === 'admin' && user?.infoFilled !== 'True') {
      return <Navigate to={routes.panel.admin.isInfoFilled} />
    }
    if (user?.level === 'superior' && user?.infoFilled !== 'True') {
      return <Navigate to={routes.panel.superior.isInfoFilled} />
    }
    if (user?.level === 'user' && user?.infoFilled !== 'True') {
      return <Navigate to={routes.panel.user.isInfoFilled} />
    }
  }

  return (
    <div className="user-wrapper">
      {soldStatusLoader ? (
        <div className="loaderWrapper">
          <DotLoader className="login-clip-loader" color={"#3C35B3"} />
          <h1
            style={{
              color: "#3C35B3",
              fontSize: "2.4rem",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;Changing Sold Status...
          </h1>
        </div>
      ) : (
        <div className="table-border">
          <div className="table-responsive">
            {panelLoader ? (
              <div className="loaderWrapper">
                <DotLoader className="login-clip-loader" color={"#3C35B3"} />
                <h1
                  style={{
                    color: "#3C35B3",
                    fontSize: "2.4rem",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;Fetching Localities...
                </h1>
              </div>
            ) : (
              <table className="table">
                <thead className="header">
                  <tr>
                    {overViewTableHeadData?.map((item) => {
                      return (
                        <>
                          <th key={item?.id} scope="col">
                            {item?.title}
                          </th>
                        </>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {localitiesOverviewData?.map((item, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <th scope="row">{item?.organization}</th>
                          <th>{item?.localityName}</th>
                          <td>{item?.localityNo}</td>
                          <td>{item?.latest_lice_value?.toFixed(2)}</td>
                          <td>{item?.pred_5th_value?.toFixed(2)}</td>
                          <td>{item?.todo_treatment}</td>
                          <td>{item?.municipality}</td>
                          <td style={{ cursor: "pointer" }}>
                            <input
                              type="checkbox"
                              onClick={() =>
                                handleStatusChange(
                                  item?.localityNo,
                                  item?.status
                                )
                              }
                              checked={item?.status === "no" ? false : true}
                            />
                          </td>
                          {/* <td>
                                                            <label className="switch">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={
                                                                        item?.status === "no" ? false : true
                                                                    }
                                                                />
                                                                <span
                                                                    className="slider"
                                                                    onClick={() =>
                                                                        handleStatusChange(
                                                                            item?.localityNo,
                                                                            item?.status
                                                                        )
                                                                    }
                                                                ></span>
                                                            </label>
                                                            
                                                        </td> */}
                          <td style={{ cursor: "pointer" }}>
                            <Link
                              to={
                                user?.level === "admin"
                                  ? `/admin/locality/${item?.localityNo}`
                                  : `/superior/locality/${item?.localityNo}`
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="17"
                                viewBox="0 0 25 17"
                                fill="none"
                              >
                                <path
                                  d="M0 17V15.0385H12.5V17H0ZM0 10.1346V8.17308H6.25V10.1346H0ZM0 3.26923V1.30769H6.25V3.26923H0ZM23.6875 17L18.6875 11.7692C18.1458 12.2051 17.5625 12.5321 16.9375 12.75C16.3125 12.9679 15.6667 13.0769 15 13.0769C13.2708 13.0769 11.7969 12.4394 10.5781 11.1644C9.35938 9.88942 8.75 8.34744 8.75 6.53846C8.75 4.72949 9.35938 3.1875 10.5781 1.9125C11.7969 0.6375 13.2708 0 15 0C16.7292 0 18.2031 0.6375 19.4219 1.9125C20.6406 3.1875 21.25 4.72949 21.25 6.53846C21.25 7.2359 21.1458 7.91154 20.9375 8.56538C20.7292 9.21923 20.4167 9.82949 20 10.3962L25 15.6269L23.6875 17ZM14.9927 11.1154C16.2059 11.1154 17.2396 10.6712 18.0938 9.78268C18.9479 8.89424 19.375 7.81539 19.375 6.54614C19.375 5.27692 18.9504 4.19551 18.1011 3.30192C17.2518 2.40833 16.2206 1.96154 15.0073 1.96154C13.7941 1.96154 12.7604 2.40577 11.9062 3.29424C11.0521 4.18269 10.625 5.26153 10.625 6.53078C10.625 7.8 11.0496 8.88141 11.8989 9.775C12.7482 10.6686 13.7794 11.1154 14.9927 11.1154Z"
                                  fill="#3F9DD9"
                                />
                              </svg>
                            </Link>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default LocalitiesOverview;
