import React, { useEffect, useState } from "react";
import { MdPerson, MdSaveAs, MdSearch } from "react-icons/md";
import Button from "../../common/Button";
import { localitiesAllUser, overViewTableHead } from "../../../constants/Home";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsersData } from "../../../redux/slice/LocalitySlice";
import { getLocalitiesOverview } from "../../../redux/slice/PanelSlice";
import Popup from "../../common/Popup";
import TextField from "../../common/TextField";
import Select from "react-select";
import { ClipLoader, DotLoader } from "react-spinners";
import { toast } from "react-toastify";
import axios from "axios";
import api from "../../../config/api";
import { Navigate } from "react-router-dom";
import routes from "../../../config/routes";

const UserDataList = () => {
  const [createUserLoader, setCreateUserLoader] = useState(false);

  const [values, setValues] = useState({
    level: "",
    email: "",
    company: "",
    accessLocalities: [],
  });

  const handleNewChange = (value) => {
    handlePopupChange({
      target: { value: value?.value, name: "level" },
    });
  };

  const handlePopupChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleKeyPress = (e) => {
    if (e.key === "Tab" || e.key === "Enter") {
      handleAccessLocalities(e);
    }
  };

  const handleSendEmail = async (e) => {
    e.preventDefault();

    if (!values.level) {
      toast.error("Select user level.");
    } else if (!values.email) {
      toast.error("Enter user email.");
    } else if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        values.email
      )
    ) {
      toast.error("Email format not correct.");
    } else if(!values.company) {
      toast.error('Enter company.')
    } else if (
      values.level === "user" &&
      values.accessLocalities.length === 0
    ) {
      toast.error("Please select atleast one locality.");
    } else {
      setCreateUserLoader(true);
      setUserPopupToggle(false);
      setDataPopupToggle(false);
      const payload = new FormData();
      payload.append("userlevel", values.level);
      payload.append("email", values.email);
      payload.append("company", values.company);
      payload.append("localities", values.accessLocalities);

      await axios
        .post(api.panel.locality.createuser, payload, config)
        .then((res) => {
          setCreateUserLoader(false);
          toast.success("User created.");
          dispatch(getAllUsersData({ search: "", config }));

          values.accessLocalities = [];
          values.email = "";
          values.level = "";
          setSelectedRows([]);
        })
        .catch((err) => {
          setCreateUserLoader(false);
        });
    }
  };

  const dispatch = useDispatch();

  const [localitiesOverViewSearch, setLocalitiesOverviewSearch] = useState("");

  const [userPopupToggle, setUserPopupToggle] = useState(false);
  const [dataPopupToggle, setDataPopupToggle] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const [itemToEdit, setItemToEdit] = useState(null);
  const [editFirstName, setEditFirstName] = useState('');
  const [editLastName, setEditLastName] = useState('');
  const [editPhoneNumber, setEditPhoneNumber] = useState('');
  const [itemRole, setItemRole] = useState(null);

  const handleEditSelect = (value) => {
    setItemRole(value);
  }

  useEffect(() => {
    setEditFirstName(itemToEdit?.fname);
    setEditLastName(itemToEdit?.lname);
    setItemRole({ label: itemToEdit?.level, value: itemToEdit?.level });
    setEditPhoneNumber(isNaN(itemToEdit?.contact) ? '' : itemToEdit?.contact);
  }, [itemToEdit])

  const panelLoader = useSelector((state) => state?.PanelSlice?.panelLoader);

  const superiorOptions = [
    { value: "admin", label: "Admin" },
    { value: "superior", label: "Superior" },
    { value: "user", label: "User" },
  ];

  const adminOptions = [
    { value: "admin", label: "Admin" },
    { value: "user", label: "User" },
  ];

  const commonSelectStyles = {
    option: (provided, state) => ({
      ...provided,
      zIndex: 10,
      backgroundColor: state.isSelected ? "#3c35b3" : "white",
      color: state.isSelected ? "white" : "#3c35b3",
      ":active": {
        ...provided[":active"],
        backgroundColor: "#3c35b3",
        color: "white",
      },
      ":hover": {
        ...provided[":hover"],
        backgroundColor: "#3c35b3",
        color: "white",
      },
    }),
  };

  const userToParse = useSelector((state) => state?.userAuthSlice?.user);
  const userAndDataLoader = useSelector(
    (state) => state?.LocalitySlice?.userAndDataLoader
  );

  const user = JSON.parse(userToParse) ? JSON.parse(userToParse) : userToParse;
  const userId = user?.user_id;

  const token = useSelector((state) => state?.userAuthSlice?.token);
  const config = {
    headers: { token: `${JSON.parse(token)}` },
  };

  useEffect(() => {
    dispatch(getAllUsersData({ search: "", config }));
    dispatch(
      getLocalitiesOverview({
        userId,
        search: "",
        config,
      })
    );
  }, []);

  const localitiesOverviewData = useSelector(
    (state) => state?.PanelSlice?.localitiesOverviewData
  );

  const allUsers = useSelector((state) => state?.LocalitySlice?.users);

  const handleCheckboxChange = (e, row) => {
    setSelectedRows((prevSelectedRows) => {
      if (e.target.checked) {
        if (
          !prevSelectedRows.some(
            (selectedRow) => selectedRow.localityName === row.localityName
          )
        ) {
          return [...prevSelectedRows, row];
        }
      } else {
        return prevSelectedRows.filter(
          (selectedRow) => selectedRow.localityName !== row.localityName
        );
      }
      return prevSelectedRows;
    });
  };

  useEffect(() => {
    const localityNos = selectedRows.map((item) => item.localityNo);
    values.accessLocalities = localityNos;
  }, [selectedRows]);

  const handleLocalitiesOverviewSearch = (e) => {
    e.preventDefault();
    dispatch(
      getLocalitiesOverview({
        userId,
        search: localitiesOverViewSearch,
        config,
      })
    );
  };

  const handleKeyPressOverview = (e) => {
    if (e.key === "Tab" || e.key === "Enter") {
      handleLocalitiesOverviewSearch(e);
    }
  };

  const handleAccessLocalities = (e) => {
    e.preventDefault();
    if (!values.level) {
      toast.error("Select user level first.");
    } else if (values.level === "admin" || values.level === "superior") {
      toast.info(`${values.level} has access to all localities.`);
    } else {
      setDataPopupToggle(true);
    }
  };

  const handleEditPopupToggle = (item) => {
    setItemToEdit(item);
    setEditPopup(true);
  }

  const handleEditKeyPress = (e) => {
    if (e.key === "Tab" || e.key === "Enter") {
      handleSaveChanges(e);
    }
  };


  const handleSaveChanges = async (e) => {
    e.preventDefault();
    setCreateUserLoader(true);

    const payload = new FormData();

    const company = 'anlice';
    const email = itemToEdit?.email;
    const password = itemToEdit?.password;
    const selectedUserId = itemToEdit?.user_id;

    payload.append('firstname', editFirstName)
    payload.append("lastname", editLastName);
    payload.append('company', company);
    payload.append('phoneNumber', editPhoneNumber ? Number(editPhoneNumber) : 0);
    payload.append('email', email);
    payload.append('password', password);
    payload.append('userId', selectedUserId);

    await axios.post(api.panel.editUser(userId), payload, config)
      .then((response) => {
        toast.success('Information updated.')
        setCreateUserLoader(false);
        setEditPopup(false);
        dispatch(getAllUsersData({ search: "", config }));
      })
      .catch((err) => {
        setCreateUserLoader(false);
      })

  }

  if (user && token) {
    if (user?.level === 'admin' && user?.infoFilled !== 'True') {
      return <Navigate to={routes.panel.admin.isInfoFilled} />
    }
    if (user?.level === 'superior' && user?.infoFilled !== 'True') {
      return <Navigate to={routes.panel.superior.isInfoFilled} />
    }
    if (user?.level === 'user' && user?.infoFilled !== 'True') {
      return <Navigate to={routes.panel.user.isInfoFilled} />
    }
  }

  return (
    <>
      {createUserLoader ? (
        <div className="loader">
          <ClipLoader size={40} color={"#3C35B3"} />
          <h1
            style={{
              color: "#3C35B3",
              fontSize: "2.4rem",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;Please wait...
          </h1>
        </div>
      ) : (
        <div className="user-wrapper">
          <div className="mobile-wrapper">
            <Button
              id="primary-button"
              className="user-profile-button"
              featureIcon={<MdPerson size={25} />}
              onClick={() => setUserPopupToggle(true)}
            >
              <span className="btn-title">Add New User</span>
            </Button>
          </div>

          {userAndDataLoader ? (
            <div className="loader">
              <ClipLoader size={40} color={"#3C35B3"} />
            </div>
          ) : (
            <div className="table-border">
              <div className="table-responsive">
                <table className="table">
                  <thead className="header">
                    <tr>
                      {localitiesAllUser?.map((item, index) => {
                        return (
                          <>
                            {index !== 7 && (
                              <th key={item?.id} scope="col">
                                {item?.title}
                              </th>
                            )}
                          </>
                        );
                      })}
                    </tr>
                  </thead>

                  <tbody>
                    {allUsers?.length !== 0 &&
                      allUsers?.map((item) => {
                        return (
                          <>
                            <tr
                              onClick={() => handleEditPopupToggle(item)}
                              style={{ cursor: "pointer" }}
                            >
                              <th scope="row">
                                {`${item?.fname === null ? "--" : item?.fname
                                  } ${item?.lname === null ? "--" : item?.lname}`}
                              </th>
                              <td>
                                {item?.company === null ? "--" : item?.company}
                              </td>
                              <td>
                                {item?.email === null ? "--" : item?.email}
                              </td>
                              <td>
                                {item?.level === null ? "--" : item?.level}
                              </td>
                              <td>
                                {item?.location === null
                                  ? "--"
                                  : item?.location}
                              </td>
                              <td>{item?.area === null ? "--" : item?.area}</td>
                              <td>
                                <MdSaveAs color="#3F9DD9" />
                              </td>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </table>
                {allUsers?.length === 0 && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <p style={{ fontSize: "2rem" }}>Sorry No Data available</p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}

      {userPopupToggle && (
        <Popup handleCancle={() => setUserPopupToggle(false)}>
          <h5>Add New User</h5>
          <Select
            onChange={(value) => handleNewChange(value)}
            options={user?.level === "admin" ? adminOptions : superiorOptions}
            isSearchable={true}
            styles={commonSelectStyles}
            placeholder="User Level"
          />
          <TextField
            placeholder="Email"
            className="popup-input"
            name="email"
            values={values.email}
            onChange={handlePopupChange}
            onKeyDown={handleKeyPress}
          />
          <TextField
            placeholder="Company"
            name='company'
            className="popup-input"
            value={values.company}
            onChange={handlePopupChange}
            onKeyDown={handleKeyPress}
          />
          {/* <Select
              options={user?.level === 'admin' ? adminOptions : superiorOptions}
              isSearchable={true}
              styles={commonSelectStyles}
              placeholder="Company"
            /> */}
          <button className="access" onClick={handleAccessLocalities}>
            Access Localities
          </button>
          {values.accessLocalities.length !== 0 && (
            <ul className="access-locailties-list">
              {values.accessLocalities.map((item) => {
                return <li> {item}</li>;
              })}
            </ul>
          )}
          <Button id="primary-button" onClick={handleSendEmail}>
            Send E-mail
          </Button>
        </Popup>
      )}
      {dataPopupToggle && (
        <Popup
          handleCancle={() => setDataPopupToggle(false)}
          className="data-popup"
        >
          <div className="search-wrapper">
            <h4>Access Localities</h4>
            <span className="search">
              <input
                type="search"
                placeholder="Search"
                value={localitiesOverViewSearch}
                onChange={(e) => setLocalitiesOverviewSearch(e.target.value)}
                onKeyDown={handleKeyPressOverview}
              />
              <MdSearch
                size={20}
                onClick={handleLocalitiesOverviewSearch}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <div className="user-wrapper">
            <div className="table-responsive">
              {panelLoader ? (
                <div className="loaderWrapper">
                  <DotLoader className="login-clip-loader" color={"#3C35B3"} />
                  <h1
                    style={{
                      color: "#3C35B3",
                      fontSize: "2.4rem",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;Fetching Localities...
                  </h1>
                </div>
              ) : (
                <table className="table">
                  <thead className="header">
                    <tr>
                      {overViewTableHead?.map((item, index) => {
                        return (
                          <>
                            {
                              <th key={item?.id} scope="col">
                                {item?.title}
                              </th>
                            }
                          </>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {localitiesOverviewData?.map((item) => {
                      return (
                        <>
                          <tr key={item?.id}>
                            <th scope="row">{item?.localityName}</th>
                            <td>{item?.localityNo}</td>
                            <td>{item?.latest_lice_value?.toFixed(2)}</td>
                            <td>{item?.pred_5th_value?.toFixed(2)}</td>
                            <td style={{ cursor: "pointer" }}>
                              <input
                                type="checkbox"
                                onChange={(e) => handleCheckboxChange(e, item)}
                                checked={selectedRows.some(
                                  (selectedRow) =>
                                    selectedRow.localityName ===
                                    item.localityName
                                )}
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </Popup>
      )}
      {editPopup && (
        <Popup handleCancle={() => setEditPopup(false)}>
          <h5>Edit User</h5>
          <Select
            onChange={(value) => handleEditSelect(value)}
            options={user?.level === "admin" ? adminOptions : superiorOptions}
            value={itemRole}
            isSearchable={true}
            styles={commonSelectStyles}
            placeholder="User Level"
          />
          <TextField
            placeholder="First Name"
            className="popup-input"
            name="firstName"
            value={editFirstName}
            onChange={(e) => setEditFirstName(e.target.value)}
            onKeyDown={handleEditKeyPress}
          />
          <TextField
            placeholder="Last Name"
            className="popup-input"
            name="lastName"
            value={editLastName}
            onChange={(e) => setEditLastName(e.target.value)}
            onKeyDown={handleEditKeyPress}
          />
          <TextField
            placeholder="Company"
            className="popup-input"
            value="Anlice"
            isDisabled
            onKeyDown={handleEditKeyPress}
          />
          <TextField
            placeholder="Email"
            className="popup-input"
            value={itemToEdit?.email}
            isDisabled
            onKeyDown={handleEditKeyPress}
          />
          <TextField
            placeholder="Phone number"
            className="popup-input"
            value={editPhoneNumber}
            onChange={(e) => setEditPhoneNumber(e.target.value)}
            onKeyDown={handleEditKeyPress}
          />
          {/* <Select
              options={user?.level === 'admin' ? adminOptions : superiorOptions}
              isSearchable={true}
              styles={commonSelectStyles}
              placeholder="Company"
            /> */}
          {/* <button className="access" onClick={handleAccessLocalities}>
            Access Localities
          </button>
          {values.accessLocalities.length !== 0 && (
            <ul className="access-locailties-list">
              {values.accessLocalities.map((item) => {
                return <li>Locality Number = {item}</li>;
              })}
            </ul>
          )} */}
          <Button id="primary-button" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Popup>
      )}
    </>
  );
};

export default UserDataList;
