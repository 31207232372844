import React, { useEffect, useState } from "react";
import Header from "../containers/Header/Header";
import Footer from "../containers/Footer/Footer";

const Layout = ({ children, handleToggle }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const mode = localStorage.getItem("modeName");

  const themeToggle = () => {
    if (isDarkMode === true) {
      setIsDarkMode(false);
      localStorage.setItem("modeName", "");
    } else {
      setIsDarkMode(true);
      localStorage.setItem("modeName", "dark");
    }
  };

  return (
    <>
      <section id="lice-layout">
        <div className={`layout-wrapper ${mode === "dark" ? "dark" : ""}`}>
          <Header onclick={themeToggle} click={handleToggle} />
          <div className="layout-content">{children}</div>
          <Footer className="layout-footer" />
        </div>
      </section>
    </>
  );
};

export default Layout;
