import React, { useState } from "react";
import Popup from "../common/Popup";
import TextField from "../common/TextField";
import '../Home/home.scss';
import Button from "../common/Button";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import routes from "../../config/routes";
import { toast } from "react-toastify";
import axios from "axios";
import api from "../../config/api";
import { DotLoader } from "react-spinners";

const PersonalInfoFilled = () => {

    const userToParse = useSelector((state) => state?.userAuthSlice?.user) || localStorage.getItem('User');

    const token = useSelector((state) => state?.userAuthSlice?.token) || localStorage.getItem('Token');

    const user = JSON.parse(userToParse) ? JSON.parse(userToParse) : userToParse;
    const userId = user?.user_id;

    const config = {
        headers: { token: `${JSON.parse(token)}` },
    }

    const [loader, setLoader] = useState(false);

    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: user?.email ? user?.email : '',
        company: user?.company ? user?.company : 'Anlice',
        password: '',
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!values.firstName) {
            toast.error("firstName is required.");
        } else if (
            /^(?=.*[0-9])/i.test(values.firstName) ||
            /^(?=.*[!@#$%^&*])/i.test(values.firstName)
        ) {
            toast.error("Firstname can not have numeric and special characters.");
        } else if (!values.lastName) {
            toast.error("lastName is required.");
        } else if (
            /^(?=.*[0-9])/i.test(values.lastName) ||
            /^(?=.*[!@#$%^&*])/i.test(values.lastName)
        ) {
            toast.error("Lastname can not have numeric and special characters.");
        } else if (!values.phoneNumber) {
            toast.error('Please enter your phone number.');
        } else if (
            /^(?=.*[a-z])/i.test(values.phoneNumber) ||
            /^(?=.*[!@#$%^&*])/i.test(values.phoneNumber)) {
            toast.error('Phone number can not have alphabets and numeric characters.');
        } else {

            setLoader(true);
            const payload = new FormData();

            payload.append('firstname', values.firstName);
            payload.append("lastname", values.lastName);
            payload.append("phoneNumber", values.phoneNumber);
            payload.append('company', values.company);
            payload.append('email', values.email);
            payload.append('password', values.password);

            await axios.post(api.panel.infoFilled(userId), payload, config)
                .then(async (res) => {
                    toast.success('Profile information updated.');

                    await axios.get(api.auth.getUserDetails(userId), config)
                        .then((response) => {
                            setLoader(false);
                            localStorage.setItem("User", JSON.stringify(response?.data));
                            window.location.reload();
                        })
                })
                .catch((err) => {
                    setLoader(false);
                    console.log({ err })
                })
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Tab' || e.key === 'Enter') {
            handleSubmit(e)
        }
    };

    if (user && token) {
        if (user?.level === 'admin' && user?.infoFilled === 'True') {
            return <Navigate to={routes.panel.admin.facts} />
        }
        if (user?.level === 'superior' && user?.infoFilled === 'True') {
            return <Navigate to={routes.panel.superior.facts} />
        }
        if (user?.level === 'user' && user?.infoFilled === 'True') {
            return <Navigate to={routes.panel.user.home} />
        }
    }

    return (
        <section id="new-user-info">
            {
                loader ?
                    <>
                        <div className="loaderWrapper">
                            <DotLoader className="login-clip-loader" color={"#3C35B3"} />
                            <h1
                                style={{
                                    color: "#3C35B3",
                                    fontSize: "2.4rem",
                                }}
                            >
                            </h1>
                        </div>
                    </>
                    :
                    <Popup>
                        <div>
                            <h1 style={{textAlign: 'center'}}>Personal Info Form</h1>
                        </div>
                        <div className="custom-row">
                            <TextField placeholder="First Name" name='firstName' value={values.firstName} onChange={handleChange} onKeyDown={handleKeyPress} />
                            <TextField placeholder=" Last Name" name='lastName' value={values.lastName} onChange={handleChange} onKeyDown={handleKeyPress} />
                        </div>
                        <TextField placeholder="Enter Your Phone Number" name='phoneNumber' value={values.phoneNumber} onChange={handleChange} onKeyDown={handleKeyPress} />
                        <TextField placeholder="Email" name='email' value={values.email} onKeyDown={handleKeyPress} isDisabled />
                        <TextField placeholder="Company" name='company' value={values.company} onKeyDown={handleKeyPress} isDisabled />
                        <TextField placeholder="Password" name='password' value={values.password} onChange={handleChange} onKeyDown={handleKeyPress}/>
                        <Button id="primary-button" onClick={handleSubmit}>Submit</Button>
                    </Popup>
            }

        </section>
    )
}

export default PersonalInfoFilled;
