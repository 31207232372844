// localitySlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../api/api";

const initialState = {
  users: [],
  data: [],
  status: true,
  error: null,
  localityLoader: false,
  summaryData: {},
  userAndDataLoader: false,
};

export const getAllUsersData = createAsyncThunk('getAllUsersData', async ({search, config}) => {
  const response = await api.getAllUsers(search, config);
  const cleanedInput = response.data.trim();
  const replacedInput = cleanedInput.replace(/NaN/g, "null");
  const allUserData = JSON.parse(replacedInput);
  return allUserData;
})

export const fetchAllUsers = createAsyncThunk("localityAllUsers", async () => {
  const response = await api.localitiesAllUser();
  // const cleanedInput = response.data.trim();
  // const replacedInput = cleanedInput.replace(/NaN/g, "null");
  // const allUserData = JSON.parse(replacedInput);
  return response.data;
});

export const fetchAllData = createAsyncThunk("localityAllData", async () => {
  const response = await api.dataAllLocalities();
  return response.data;
});

export const getSummaryDetails = createAsyncThunk('getSummaryDetails', async ({userId, config}) => {
  const response = await api.summaryDetails(userId, config);
  return response.data;
})


const LocalitySlice = createSlice({
  name: "locality",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUsers.pending, (state) => {
        state.status = true;
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.status = false;
        state.users = action.payload;
      })
      .addCase(fetchAllUsers.rejected, (state, action) => {
        state.status = false;
        state.error = action.error.message;
      })
      .addCase(fetchAllData.pending, (state) => {
        state.status = true;
      })
      .addCase(fetchAllData.fulfilled, (state, action) => {
        state.status = false;
        state.users = action.payload;
      })
      .addCase(fetchAllData.rejected, (state, action) => {
        state.status = false;
        state.error = action.error.message;
      })
      .addCase(getSummaryDetails.pending, (state) => {
        state.localityLoader = true;
      })
      .addCase(getSummaryDetails.fulfilled, (state, action) => {
        state.localityLoader = false;
        state.summaryData = action.payload;
      })
      .addCase(getSummaryDetails.rejected, (state) => {
        state.localityLoader = false;
      })
      .addCase(getAllUsersData.pending, (state) => {
        state.userAndDataLoader = true;
      })
      .addCase(getAllUsersData.fulfilled, (state, action) => {
        state.userAndDataLoader = false;
        state.users = action.payload;
        // state.users = JSON.parse(action.payload);
        // console.log('asa', (action.payload))
      })
      .addCase(getAllUsersData.rejected, (state) => {
        state.userAndDataLoader = false;
      })
  },
});

export default LocalitySlice.reducer;
