const validate = (values) => {
    let errors = {};

    if (!values.currentPassword) {
        errors.currentPassword = "Enter your current password.";
    }

    else if (!values.newPassword) {
        errors.newPassword = "Enter your new password.";
    }
    else if (values.newPassword.length < 8 || !/[A-Z]/.test(values.newPassword) || !/[0-9]/.test(values.newPassword)) {
        errors.newPassword = "Password must be at least 8 characters with 1 upper case letter and 1 number.";
    }

    else if (!values.confirmNewPassword) {
        errors.confirmNewPassword = "Enter confirm password.";
    }

    else if (values.confirmNewPassword !== values.newPassword) {
        errors.confirmNewPassword = "Passwords do not match.";
    }

    return errors;
};

export default validate;
