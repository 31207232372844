const validate = (values) => {
  let errors = {};

  if (!values.firstname) {
    errors.firstname = "Firstname is required.";
  } else if (
    /^(?=.*[0-9])/i.test(values.firstname) ||
    /^(?=.*[!@#$%^&*])/i.test(values.firstname)
  ) {
    errors.firstname = "Firstname can not have numeric and special characters.";
  } else if (!values.lastname) {
    errors.lastname = "Lastname is required.";
  } else if (
    /^(?=.*[0-9])/i.test(values.lastname) ||
    /^(?=.*[!@#$%^&*])/i.test(values.lastname)
  ) {
    errors.lastname = "Lastname can not have numeric and special characters.";
  } else if (!values.phoneNumber) {
    errors.phoneNumber = 'Please enter your phone number.';
  } else if (
    /^(?=.*[a-z])/i.test(values.phoneNumber) ||
    /^(?=.*[!@#$%^&*])/i.test(values.phoneNumber)) {
    errors.phoneNumber = 'Phone number can not have alphabets and numeric characters.'
  } else if (!values.email) {
    errors.email = "Email is required.";
  } else if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      values.email
    )
  ) {
    errors.email = "Invalid email address.";
  } else if (!values.company) {
    errors.company = "Enter your company.";
  }

  return errors;
};

export default validate;
