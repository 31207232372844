import axios from "axios";
import { toast } from "react-toastify";

const initTransportInterceptor = (store) => {
  // TODO: Token should be sent using this snippet
  /*
    axios
      .interceptors
      .request
      .use(
        (config) => {
          const token = localStorage.getItem('Token');
  
          config.headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          };
  
          return config;
        },
      );
    */

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (err) => {
      const error = {
        status: err?.response?.status,
        title: err?.response?.data?.message,
      };

      if (error.status === 400 && error.title === "Invalid session token") {
        localStorage.clear();
        const message = `${error.status} / ${error.title}`;
        toast.error(message);
      }
      else if(error.status === 500) {
        const message = `${error.status} / Internal Server Error\nContact support team.`;
        toast.error(message)
      }
      else if(error.status === undefined && error.title === undefined) {
        
      }
      else {
        const message = `${error.status} / ${error.title}`;
        toast.error(message);
      }

      return Promise.reject(err);
    }
  );
};

const initInterceptors = (store) => {
  initTransportInterceptor(store);
};

export default initInterceptors;
