import React, { useState } from "react";
import Layout from "./Layout";
import { SettingSidebar, Sidebar } from "../containers/Sidebar/Sidebar";
import routes from "../config/routes";

const DashboardLayout = ({ children }) => {
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const settingMenu = [
    routes?.panel?.admin?.settings?.generalInfo,
    routes?.panel?.admin?.settings?.password,
    routes?.panel?.admin?.settings?.language,
  ];
  const sidebarToggle = window.location.pathname.includes("/setting");

  const sideBarToggle = ()=>{
    setSideBarOpen(!sideBarOpen);
  }
  return (
    <>
      <Layout handleToggle={sideBarToggle}>
        <div id="dashboard-layout">
          <div className={`sidebar ${sideBarOpen ? "side-bar-open" : ""}`}>
            {sidebarToggle ? <SettingSidebar /> : <Sidebar />}
            {/* <div
              className="sidebar-menu"
              // onClick={() => {
              //   setSideBarOpen(!sideBarOpen);
              // }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="7"
                viewBox="0 0 11 7"
                fill="none"
              >
                <path
                  d="M5.5 7L0 1.0636L0.985417 0L5.5 4.89753L10.0146 0.024735L11 1.08834L5.5 7Z"
                  fill="white"
                />
              </svg>
            </div> */}
          </div>
          <div
            className={`content ${sideBarOpen ? "sidebar-open-content" : ""}`}
            onClick={()=>setSideBarOpen(false)}
          >
            {children}
          </div>
        </div>
        <div></div>
      </Layout>
    </>
  );
};

export default DashboardLayout;
