import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../api/api";
import { toast } from "react-toastify";

const initialState = {
  settingLoader: false,
};

export const updateUserPersonalInfo = createAsyncThunk(
  "updateUserPersonalInfo",
  async ({ userId, payload, config }) => {
    const response = await api.updatePersonalInfo(userId, payload, config);
    return response.data;
  }
);

export const updateUserPassword = createAsyncThunk('updateUserPassword', async ({userId, payload, config}) => {
  const response = await api.updatePassword(userId, payload, config);
  return response.data;
})

const SettingSlice = createSlice({
  name: "SettingSlice",
  initialState,

  extraReducers: (builder) => {
    
    //  User login Api process
    builder.addCase(updateUserPersonalInfo.pending, (state) => {
      state.settingLoader = true;
    });
    builder.addCase(updateUserPersonalInfo.fulfilled, (state, action) => {
      state.settingLoader = false;
      toast.success('Profile information updated.')
    });
    builder.addCase(updateUserPersonalInfo.rejected, (state, action) => {
      state.settingLoader = false;
    });

    builder.addCase(updateUserPassword.pending, (state) => {
      state.settingLoader = true;
    });
    builder.addCase(updateUserPassword.fulfilled, (state, action) => {
      state.settingLoader = false;
      toast.success('Password changed successfully.')
    });
    builder.addCase(updateUserPassword.rejected, (state, action) => {
      state.settingLoader = false;
    });
  },
});

export default SettingSlice.reducer;
