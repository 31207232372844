import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../api/api";
import { toast } from "react-toastify";

const initialState = {
  user: localStorage?.getItem("User") || "",
  token: localStorage.getItem("Token") || "",
  authLoader: false,
  redirectSuccess: false,
};

export const userLogin = createAsyncThunk("userLogin", async (payload) => {
  const response = await api.loginUser(payload);
  return response.data;
});

export const getUserDetailsInfo = createAsyncThunk(
  "getUserDetailsInfo",
  async ({ userId, config }) => {
    const response = await api.getUserDetails(userId, config);
    return response.data;
  }
);

const userAuthSlice = createSlice({
  name: "userAuthSlice",
  initialState,

  reducers: {
    logout: (state) => {
      localStorage.clear();
      state.user = "";
      state.token = "";
      window.location.reload();
    },
  },

  extraReducers: (builder, state) => {
    //  User login Api process
    builder.addCase(userLogin.pending, (state) => {
      state.authLoader = true;
      state.redirectSuccess = false;
    });

    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.user = JSON.stringify(action.payload?.user);
      localStorage.setItem("User", JSON.stringify(action.payload?.user));
      state.token = JSON.stringify(action.payload?.token);
      localStorage.setItem("Token", JSON.stringify(action.payload?.token));
      state.authLoader = false;
      console.log({action})
      toast.success("Congratulations you are logged in.");
      state.redirectSuccess = true;
    });
    builder.addCase(userLogin.rejected, (state, action) => {
      state.authLoader = false;
      state.redirectSuccess = false;
    });

    builder.addCase(getUserDetailsInfo.fulfilled, (state, action) => {
      state.user = JSON.stringify(action.payload);
      localStorage.setItem("User", JSON.stringify(action.payload));
    })
  },
});

export default userAuthSlice.reducer;
export const { logout } = userAuthSlice.actions;
