import React, { useEffect, useState } from "react";
import "./overviewTable.scss";
import { overViewTableBody, overViewTableHead, overViewTableHeadTest } from "../../../constants/Home";
import { useDispatch, useSelector } from "react-redux";
import { panelHomeLocalities } from "../../../redux/slice/PanelSlice";
import { ClimbingBoxLoader, DotLoader } from "react-spinners";
import { Navigate } from "react-router-dom";

const OverviewTable = ({tableData}) => {

  const [redirectLocality, setRedirectLocality] = useState('');

  const userToParse = useSelector((state) => state?.userAuthSlice?.user);

  const user = JSON.parse(userToParse) ? JSON.parse(userToParse) : userToParse;

  const userId = user?.user_id;

  const dispatch = useDispatch();
  const homeLocalitiesLoader = useSelector((state) => state?.PanelSlice?.homeLocalitiesLoader);

  const handleOverViewRedirection = (item) => {
    setRedirectLocality(`${item?.localityNo}`)
  }

  if(redirectLocality !== '') {
    return <Navigate to={`/user/locality/${redirectLocality}`}/>
  } 

  return (
    <section id="overview-table-wrapper">
      <h4>Overview</h4>
      <div className="table-border">
      <div className="table-responsive">
        {
          homeLocalitiesLoader ?
            <div
              className="col-lg-12 col-md-12 col-sm-12 col-12"
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflowY: 'hidden'
              }}>
              <ClimbingBoxLoader color={"#3C35B3"} />
              <h1
                style={{
                  color: "#3C35B3",
                  fontSize: "2.4rem",
                }}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;Please Wait...
              </h1>
            </div>
            :
            <table className="table">
              <thead className="header">
                <tr>
                  {overViewTableHeadTest?.map((item, index) => {
                    return (
                      <>
                        <th key={index} scope="col">
                          {item?.title}
                        </th>
                      </>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {
                  tableData !== null && tableData?.length === 0 ?
                    <div
                      className="col-lg-12 col-md-12 col-sm-12 col-12"
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}>
                      <h3 style={{ color: "#3C35B3", marginBottom: "0px" }}>
                        Sorry No Data Available
                      </h3>
                    </div>
                    :
                    <>
                      {tableData !== null && tableData?.map((item, index) => {
                        return (
                          <>
                            {
                              <tr style={{ cursor: 'pointer' }} onClick={() => handleOverViewRedirection(item)}>
                                <th scope="row">{item?.localityName}</th>
                                <td>{item?.localityNo}</td>
                                <td>{(item?.latest_lice_value)?.toFixed(2)}</td>
                                <td>{(item?.pred_5th_value)?.toFixed(2)}</td>
                                <td>{item?.todo_treatment}</td>
                                <td>{item?.municipality}</td>
                                <td >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="12"
                                    viewBox="0 0 15 12"
                                    fill="none"
                                  >
                                    <path
                                      d="M9.16667 11.4541L8.18125 10.4916L12.0312 6.64157H0V5.26657H12.0312L8.15833 1.39365L9.14375 0.431152L14.6667 5.95407L9.16667 11.4541Z"
                                      fill="#3F9DD9"
                                    />
                                  </svg>
                                </td>
                              </tr>
                            }
                          </>
                        );
                      })}
                    </>
                }
              </tbody>
            </table>
        }
      </div>
      </div>
   
    </section>
  );
};

export default OverviewTable;
